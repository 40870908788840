import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const PieChart2 = ({
  title = 'HRAs by State',
  type = 'pie',
  dataPoints = [
    { y: 32, label: 'Health' },
    { y: 22, label: 'Finance' },
    { y: 15, label: 'Education' },
    { y: 19, label: 'Career' },
    { y: 5, label: 'Family' },
    { y: 7, label: 'Real Estate' },
  ],
}) => {
  const options = {
    theme: 'dark2',
    animationEnabled: true,
    exportFileName: 'New Year Resolutions',
    // exportEnabled: true,
    title: {
      text: title,
    },
    data: [
      {
        type,
        showInLegend: true,
        legendText: '{label}',
        toolTipContent: '{label}: <strong>{y}%</strong>',
        indexLabel: '{y}%',
        indexLabelPlacement: 'inside',
        dataPoints,
      },
    ],
  };
  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default PieChart2;


import CanvasJSReact from '@canvasjs/react-charts';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const dataPoints = [
    { label: 'Mon', totalConsent: 0, totalSignedConsent: 0 ,totalEligibility:0,totalSignedEligibility:0},
    { label: 'Tue', totalConsent: 0, totalSignedConsent: 0 ,totalEligibility:0,totalSignedEligibility:0},
    { label: 'Wed', totalConsent: 0, totalSignedConsent: 0 ,totalEligibility:0,totalSignedEligibility:0},
    { label: 'Thu', totalConsent: 0, totalSignedConsent: 0 ,totalEligibility:0,totalSignedEligibility:0},
    { label: 'Fri', totalConsent: 0, totalSignedConsent: 0 ,totalEligibility:0,totalSignedEligibility:0},
    { label: 'Sat', totalConsent: 0, totalSignedConsent: 0 ,totalEligibility:0,totalSignedEligibility:0},
    { label: 'Sun', totalConsent: 0, totalSignedConsent: 0 ,totalEligibility:0,totalSignedEligibility:0},
  ];

const ConsentGraph = ({ graphData }) => {
  const options = {
    animationEnabled: true,
    title: {
      text: graphData?.title ? graphData?.title : 'Yearly Users Summary',
      fontSize: 35,
      fontWeight: 'semibold',
    },
    toolTip: {
      shared: true,
    },
    axisY: {
      gridColor: 'lightgray',
      gridThickness: 1,
    },
    data: [
      {
        type: 'column',
        name: 'Sent Consent',
        showInLegend: true,
        dataPoints: (graphData?.data ? graphData?.data : dataPoints).map(
          (point) => ({
            label: point.label,
            y: point.totalConsent,
          }),
        ),
        color: '#86A788',
      },
      {
        type: 'column',
        name: 'Signed Consent',
        showInLegend: true,
        dataPoints: (graphData?.data ? graphData?.data : dataPoints).map(
          (point) => ({
            label: point.label,
            y: point.totalSignedConsent,
          }),
        ),
        color: '#007193',
      },
    
      {
        type: 'column',
        name: 'Sent Eligibility',
        showInLegend: true,
        dataPoints: (graphData?.data ? graphData?.data : dataPoints).map(
          (point) => ({
            label: point.label,
            y: point.totalEligibility,
          }),
        ),
        color: '#79D7BE',
      },
      {
        type: 'column',
        name: 'Signed Eligibility',
        showInLegend: true,
        dataPoints: (graphData?.data ? graphData?.data : dataPoints).map(
          (point) => ({
            label: point.label,
            y: point.totalSignedEligibility,
          }),
        ),
        color: '#ff4e43',
      },
   
    ],
  };

  return <CanvasJSChart options={options} />;
};

export default ConsentGraph;

import { useState, useMemo, useEffect } from 'react';
import { useAlert } from '../../hooks/useAlert';
import { useQuery } from '@tanstack/react-query';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Spinner from '../Spinner';
import AsideModal from '../AsideModal';
import TableRow from './TableRow';
import Button from '../Button';
import useIcons from '../../assets/icons/useIcons';
import Tooltip from '../Tooltip';
import GlobalFilter2 from '../GlobalFilter2';
import React from 'react';

const pageNumberListLimit = 5;
const TableManager = ({
  fetcherKey = '',
  fetcherFn = () => {},
  columns = [],
  tableActions = [],
  shouldShowColumnName = true,
  extraParams = {},
  shouldFilter = false,
  showHeader = true,
  isEnabled = true, // only when api function is not avialable we pass isEnabled = false so no api error is thrown
  setApiResponse = () => {},
  selectCBForTableData = (tblData) => tblData?.data?.data,
  fullTableClass = '',
  enableMoreOption = false,
  showMoreOption = (elm) => true,
  moreDataFunction = () => null,
  moreDataFnPayload = () => null,
  moreOptioncolumns = [],
  showPagination = true,
  showPageCount = true,
  initialFilterState = '',
  name = '',
}) => {
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const { showAlert } = useAlert();
  const {
    RefreshIcon,
    ArrowRightIcon,
    DoubleArrowLeftIcon,
    ArrowLeftIcon,
    DoubleArrowRightIcon,
    FilterIcon,
  } = useIcons();
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [maxPageNumberListLimit, setMaxPageNumberListLimit] = useState(5);
  const [minPageNumberListLimit, setMinPageNumberListLimit] = useState(0);
  const memoizedSorting = useMemo(() => sorting, [sorting]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  let payload = {
    page: pageIndex + 1,
    limit: pageSize,
    sort: memoizedSorting[0] || {},
    filters,
    ...extraParams,
  };

  const {
    isSuccess,
    isLoading,
    isFetching,
    refetch,
    data: tbldata,
  } = useQuery({
    queryFn: ({ queryKey }) => fetcherFn(queryKey[1] || {}),
    queryKey: [`${fetcherKey}`, { ...payload }],
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
    enabled: isEnabled,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
  });
  useEffect(() => {
    if (isSuccess && tbldata) {
      setApiResponse(tbldata);
    }
  }, [isSuccess, setApiResponse, tbldata]);

  const tableInstance = useReactTable({
    debugAll: false,
    data: selectCBForTableData(tbldata) || [],
    columns: memoizedColumns || [],
    columnResizeMode: 'onChange',
    state: {
      pagination,
      sorting: memoizedSorting,
      rowSelection,
    },
    manualPagination: true,
    manualSorting: true,
    pageCount: tbldata?.data?.data?.totalPages ?? 1,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  const handleNextPage = () => {
    tableInstance?.nextPage();
    if (pageIndex === tableInstance?.getPageCount() - 1) {
      return false;
    } else {
      if (pageIndex + 2 > maxPageNumberListLimit) {
        setMaxPageNumberListLimit(maxPageNumberListLimit + pageNumberListLimit);
        setMinPageNumberListLimit(minPageNumberListLimit + pageNumberListLimit);
      }
    }
  };
  const handlePreviousPage = () => {
    tableInstance?.previousPage();
    if (pageIndex === 0) {
      return;
    } else {
      if (pageIndex % pageNumberListLimit === 0) {
        setMaxPageNumberListLimit(maxPageNumberListLimit - pageNumberListLimit);
        setMinPageNumberListLimit(minPageNumberListLimit - pageNumberListLimit);
      }
    }
  };
  const handleLastPage = () => {
    tableInstance?.setPageIndex(tableInstance?.getPageCount() - 1);
    const min = tableInstance.getPageCount() - 5;
    const max = tableInstance.getPageCount();

    setMaxPageNumberListLimit(max);
    setMinPageNumberListLimit(min);
  };
  const handleFirstPage = () => {
    tableInstance?.setPageIndex(0);
    const min = 0;
    const max = 5;
    setMaxPageNumberListLimit(max);
    setMinPageNumberListLimit(min);
  };

  return (
    <div className="w--full">
      {showHeader && (
        <div
          className={`w--full d--flex align-items--center  justify-content--between    h-min--50  flex--column-xs align-items--start-xs`}
        >
          <div className="font--xl font--600 w--full d--flex align-items--center gap--sm">
            {name} |
            <div className="badge font--sm text--black-600 d--flex">
              Total {tbldata?.data?.data?.totalCount || 0} record(s)
            </div>
            {isEnabled && (isLoading || isFetching) && (
              <span className="d--flex">
                <Spinner />
              </span>
            )}
          </div>
          <div className="d--flex align-items--center w--full justify-content--end">
            <div className="d--flex align-items--center justify-content--end gap--sm ">
              <span className="d--flex gap--sm">
                {tableActions &&
                  tableActions.length > 0 &&
                  tableActions.map(
                    ({ id, component: ElmComponent, access }, index) => {
                      if (!access)
                        return <React.Fragment key={index}></React.Fragment>;
                      return <span key={id}>{ElmComponent}</span>;
                    },
                  )}
              </span>

              {shouldFilter && (
                <span className="position--relative">
                  {filters.length > 0 ? (
                    <span className="bg--secondary radius--full  w-max--20 w-min--20 h-min--20 h-max--20 text--white d--flex justify-content--center align-items--center position--absolute left---8 top---5">
                      {filters.length}
                    </span>
                  ) : null}
                  <Button
                    variant="primary-200"
                    btnClasses="btn w-max--36 box-shadow--xs"
                    color="black"
                    type="button"
                    borderStyle="primary"
                    isOutline
                    icon={<FilterIcon width={20} height={20} />}
                    onClick={() => setOpenFilter(true)}
                  />
                </span>
              )}
              <Tooltip text="Refresh">
                <Button
                  variant="primary-200"
                  btnClasses="btn w-max--36 box-shadow--xs"
                  color="black"
                  type="button"
                  borderStyle="primary"
                  isOutline
                  icon={<RefreshIcon width={20} height={20} />}
                  onClick={() => {
                    if (filters.length) {
                      setFilters([]);
                    } else {
                      refetch();
                    }
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      )}

      <div
        className={`w--full  bg--white radius--sm p--md p--5-xs overflow--auto w-max--100p  `}
      >
        <div
          className={`w--full table--responsive table--responsive--search-scroll  radius--sm border-full--black-100 ${fullTableClass}`}
        >
          <table className="table ">
            {shouldShowColumnName && (
              <thead className="d--none-xs">
                {tableInstance?.getHeaderGroups()?.map((headerGroup) => (
                  <tr key={headerGroup?.id}>
                    {enableMoreOption && (
                      <th
                        style={{
                          width: 'fit-content',
                          padding: '0px',
                        }}
                      ></th>
                    )}
                    {headerGroup?.headers?.map((header) => {
                      return (
                        <th
                          className={header?.column?.columnDef?.meta?.class}
                          key={header?.id}
                          colSpan={
                            header?.column?.columnDef?.meta?.colSpan ??
                            undefined
                          }
                          style={{
                            textAlign:
                              header?.column?.columnDef?.meta?.textAlign ??
                              'left',
                            width:
                              header?.column?.columnDef?.meta?.width ||
                              `${(
                                100 / header?.headerGroup?.headers?.length
                              ).toFixed(2)}%`,
                            border: header?.column?.columnDef?.meta?.border,
                            color: header?.column?.columnDef?.meta?.color ?? '',
                          }}
                        >
                          {header?.isPlaceholder ? null : header?.column
                              .columnDef.meta?.isSortable ? (
                            <div
                              onClick={header?.column?.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                            </div>
                          ) : (
                            flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
            )}

            <tbody>
              {tableInstance?.getRowModel()?.rows?.length > 0 ? (
                tableInstance?.getRowModel()?.rows?.map((row) => {
                  return (
                    <TableRow
                      row={row}
                      flexRender={flexRender}
                      enableMoreOption={enableMoreOption}
                      showMoreOption={showMoreOption}
                      rowSelection={rowSelection}
                      moreDataFunction={moreDataFunction}
                      moreDataFnPayload={moreDataFnPayload}
                      memoizedColumns={moreOptioncolumns || memoizedColumns}
                    />
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={tableInstance?.getAllFlatColumns()?.length}
                    align="center"
                  >
                    <span className="w--full d--flex justify-content--center text--black">
                      {isEnabled && isLoading ? <Spinner /> : 'No Record Found'}
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {(showPageCount || showPagination) && (
        <div className="w--full d--flex align-items--center justify-content--between  m-t--md">
          {showPageCount && (
            <div className=" d--flex gap--sm align-items--center w--full">
              <span>
                <span className="font--600">Page </span>
                <span>
                  {tableInstance?.getState()?.pagination?.pageIndex + 1}{' '}
                </span>
                of <span>{tableInstance?.getPageCount()}</span>
              </span>
              <span>|</span>
              <span className="d--flex gap--sm align-items--center w-max--300 w--full">
                <span className="font--600">Show</span>
                <select
                  required
                  className="form--control w--full h-min--28 h-max--28 w-max--60  radius--sm p-l--xs p-r--xs"
                  value={String(
                    tableInstance?.getState()?.pagination?.pageSize,
                  )}
                  onChange={(e) => {
                    tableInstance?.setPageSize(Number(e.target.value));
                  }}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                </select>
                <span className="w--full d--flex">records per page</span>
              </span>
            </div>
          )}

          {/* !isLoading &&  this will go below showPagination */}
          {showPagination && tableInstance?.getRowModel()?.rows?.length > 0 && (
            <div className="d--flex align-items--center justify-content--end">
              <div className="d--flex gap--xs">
                <Button
                  onClick={() => handleFirstPage()}
                  disabled={!tableInstance?.getCanPreviousPage()}
                  btnClasses={` bg--white c--pointer h-min--28 h-max--28 r-t-left--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center  ${
                    !tableInstance?.getCanPreviousPage() ? 'disabled' : ''
                  } `}
                  type="button"
                  variant="white"
                  color="black"
                >
                  <DoubleArrowLeftIcon width={18} height={18} />
                </Button>
                <Button
                  onClick={() => handlePreviousPage()}
                  disabled={!tableInstance?.getCanPreviousPage()}
                  btnClasses={` bg--white c--pointer h-min--28 h-max--32 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                    !tableInstance?.getCanPreviousPage() ? 'disabled' : ''
                  } `}
                  type="button"
                  variant="white"
                  color="black"
                >
                  <ArrowLeftIcon width={18} height={18} />
                </Button>

                <div className="d--flex gap--xs align-item--center ">
                  {tableInstance?.getPageOptions()?.map((page, index) => {
                    const number = page + 1;
                    if (
                      number < maxPageNumberListLimit + 1 &&
                      number > minPageNumberListLimit
                    ) {
                      return (
                        <Button
                          key={page}
                          onClick={() => tableInstance?.setPageIndex(index)}
                          btnClasses={`  c--pointer h-min--28 h-max--28 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                            tableInstance?.getState()?.pagination?.pageIndex ===
                            index
                              ? ' bg--primary'
                              : 'bg--white '
                          } `}
                          type="button"
                          variant="white"
                          color={` ${
                            tableInstance?.getState()?.pagination?.pageIndex ===
                            index
                              ? ' text--black'
                              : 'text--black '
                          } `}
                        >
                          {page + 1}
                        </Button>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>

                <Button
                  onClick={() => handleNextPage()}
                  disabled={!tableInstance?.getCanNextPage()}
                  btnClasses={` bg--white c--pointer h-min--28 h-max--32 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                    !tableInstance?.getCanNextPage() ? 'disabled' : ''
                  } `}
                  type="button"
                  variant="white"
                  color="black"
                >
                  <ArrowRightIcon width={18} height={18} />
                </Button>
                <Button
                  onClick={() => handleLastPage()}
                  disabled={!tableInstance?.getCanNextPage()}
                  btnClasses={` bg--white c--pointer h-min--28 h-max--32 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                    !tableInstance?.getCanNextPage() ? 'disabled' : ''
                  } `}
                  type="button"
                  variant="white"
                  color="black"
                >
                  <DoubleArrowRightIcon width={18} height={18} />
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {openFilter && (
        <AsideModal
          handleClose={() => setOpenFilter(false)}
          title="Filters"
          footerComponent={null}
          headerComponent={null}
        >
          <GlobalFilter2
            filters={filters}
            setFilters={setFilters}
            setOpenFilter={setOpenFilter}
            initialFilterState={initialFilterState}
            isSearching={isLoading || isFetching}
            commented
            by
            me
          />
        </AsideModal>
      )}
    </div>
  );
};

export default TableManager;

import { useMutation } from '@tanstack/react-query';
import { login } from '../../api';
import { useAuth } from '../useAuth';
import { useAlert } from '../useAlert';
import { useNavigate } from 'react-router-dom';

export default function useLogin() {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const { showAlert } = useAlert();
  const loginRequest = useMutation({
    mutationFn: login,
    mutationKey: 'login',
    onSuccess: ({ data }) => {
      
      setUser({ token: data.data.token.token });
      navigate('dashboard');
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...loginRequest };
}

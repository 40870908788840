
import CanvasJSReact from '@canvasjs/react-charts';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const dataPoints = [
  { label: 'Mon', totalHraAgreement: 0, totalHraSingedAgreement: 0 },
  { label: 'Tue', totalHraAgreement: 0, totalHraSingedAgreement: 0 },
  { label: 'Wed', totalHraAgreement: 0, totalHraSingedAgreement: 0 },
  { label: 'Thu', totalHraAgreement: 0, totalHraSingedAgreement: 0 },
  { label: 'Fri', totalHraAgreement: 0, totalHraSingedAgreement: 0 },
  { label: 'Sat', totalHraAgreement: 0, totalHraSingedAgreement: 0 },
  { label: 'Sun', totalHraAgreement: 0, totalHraSingedAgreement: 0 },
];

const HRAGraph = ({ graphData }) => {
  const options = {
    animationEnabled: true,
    title: {
      text: graphData?.title ? graphData?.title : 'Yearly Users Summary',
      fontSize: 35,
      fontWeight: 'semibold',
    },
    toolTip: {
      shared: true,
    },
    axisY: {
      gridColor: 'lightgray',
      gridThickness: 1,
    },
    data: [
      {
        type: 'column',
        name: 'HRA Sent Agreement',
        showInLegend: true,
        dataPoints: (graphData?.data ? graphData?.data : dataPoints).map(
          (point) => ({
            label: point.label,
            y: point.totalHraAgreement,
          }),
        ),
        color: '#86A788',
      },
      {
        type: 'column',
        name: 'HRA Signed Agreement',
        showInLegend: true,
        dataPoints: (graphData?.data ? graphData?.data : dataPoints).map(
          (point) => ({
            label: point.label,
            y: point.totalHraSingedAgreement,
          }),
        ),
        color: '#007193',
      },
    
   
   
    ],
  };

  return <CanvasJSChart options={options} />;
};

export default HRAGraph;

import React from 'react';

const PlanDetail = ({ planInfo }) => {
  const { planStartDate, planRenewalDate, complianceUsed, compliance } =
    planInfo || {};
  return (
    <div className="w--full d--flex flex--column gap--md">
      <div className="label--control font--md font--500 m-b--xs  h-min--44  d--flex gap--sm align-items--center text--black bg--black-50 border-full--black-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
        Plan Info<div class="d--flex"></div>
      </div>

      <div className="d--flex flex--column border-full--black-100 radius--sm w--full">
        <div className="w--full d--flex flex--column  p-l--lg p-r--lg">
          <div className="border-bottom--black-100 d--flex align-items--center justify-content--between h-min--60">
            <div className="font--sm font--600">
              Free up to {compliance} compliance
            </div>
            <div className="font--sm d--flex align-items--center gap--sm">
              (used) {complianceUsed} / {compliance}
            </div>
          </div>
          <div className="border-bottom--black-100 d--flex align-items--center justify-content--between h-min--60">
            <div className="font--sm font--600">Plan Start Date</div>
            <div className="font--sm d--flex align-items--center gap--sm">
              {planStartDate || 'Not Applicable'}
            </div>
          </div>
          <div className="border-bottom--black-100 d--flex align-items--center justify-content--between h-min--60">
            <div className="font--sm font--600">Plan Renewal Date</div>
            <div className="font--sm d--flex align-items--center gap--sm">
              {planRenewalDate || 'Not Applicable'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDetail;

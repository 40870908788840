import moment from 'moment';

export const IdentityProofTypes = [
  { id: 1, label: 'Drivers License', value: 'drivers_license' },
  { id: 2, label: 'National ID', value: 'national_id' },
  { id: 3, label: 'Passport', value: 'passport' },
  { id: 4, label: 'Residence Permit', value: 'residence_permit' },
  { id: 5, label: 'Visa', value: 'visa' },
  { id: 6, label: 'Other', value: 'other' },
];

export const AddressProofTypes = [
  { id: 1, label: 'Copy of Phone Bill', value: 'phone_bill' },
  { id: 2, label: 'Utility Bill', value: 'utility_bill' },
  { id: 3, label: 'Rental Receipt', value: 'rental_receipt' },
  { id: 4, label: 'Other', value: 'other' },
];

export const addressProofMap = {
  phone_bill: 'Copy of Phone Bill',
  utility_bill: 'Utility Bill',
  rental_receipt: 'Rental Receipt',
  other: 'Other',
};
export const identityProofMap = {
  drivers_license: 'Drivers License',
  national_id: 'National ID',
  passport: 'Passport',
  residence_permit: 'Residence Permit',
  visa: 'Visa',
  other: 'Other',
};

export const durationToMMSS = (time) => {
  const duration = moment.duration(time);
  const minutes = Math.floor(duration.asMinutes()).toString().padStart(2, '0');
  const seconds = Math.floor(duration.asSeconds() - minutes * 60)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${seconds}`;
};

export const memberAgentslabelsArray = [
  { label: 'Employees', value: 0 },
  { label: 'Agents', value: 1 },
];

export const smslabelsArray = [
  { label: 'Sent', value: 'sent' },
  { label: 'Received', value: 'received' },
];

export const callslabelsArray = [
  { label: 'Outbound', value: 'outbound' },
  { label: 'Inbound', value: 'inbound' },
  { label: 'Missed', value: 'missed' },
];

export function generateRandomNumber(length = 4) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
}

export function formatDate(date) {
  if (!date) return;
  const newDate = new Date(date);
  return newDate.toISOString().substring(0, 10);
}
export function formatDateTime(date) {
  if (!date) return;

  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function formatDateOnly(date) {
  if (!date) return;
  return moment(date).format('MMM DD YYYY');
}

export function getInitials(name) {
  if (!name) return;
  return name ? name.charAt(0).toUpperCase() : 'NA';
}

export function stringToColour(str) {
  if (!str || !str.length) return;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

export const roleLookup = {
  1: 'Admin',
  2: 'Agency',
  3: 'Member',
  4: 'Sub Agency',
};

export const downloadFileFromURL = (url = '') => {
  if (!url) return;
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('target', '_blank');
  element.setAttribute('download', 'document');
  element.click();
};

export const getToken = () => {
  return localStorage.getItem('nph-admin-acc-tkn') || null;
};

export const dateFilterTypes = [
  { label: 'Today', value: 'Today' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'Last 7 Days', value: 'Last 7 Days' },
  { label: 'Last 30 Days', value: 'Last 30 Days' },
  { label: 'This Month', value: 'This Month' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Select Date', value: 'Select Date' },
  { label: 'Custom Range', value: 'Custom' },
];

export const convertDateFunction = (date, format = 'DD-MMM-YYYY') => {
  return moment(date).format(format);
};
export const getObjectLength = (obj) => {
  try {
    return Object.keys(obj).length;
  } catch (_) {
    return 0;
  }
};

export const submitBtnTxt = (condition, btnTxt = 'Submit') => {
  if (condition) {
    return 'Please wait...';
  } else {
    return btnTxt;
  }
};

import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useQuery } from '@tanstack/react-query';
import { getProfileDetails } from '../../api';
import Spinner from '../../components/Spinner';

const UserDetails = () => {
  const { id } = useParams();

  const { data: userDetail, isLoading } = useQuery({
    queryKey: ['get-profile-details', id],
    queryFn: () => getProfileDetails({ uniqueCodeId: id }),
    select: (data) => data?.data?.data,
    enabled: Boolean(id),
  });

  // if (isLoading) {
  //   return (
  //     <div className="d--flex align-items--center justify-content--center h--full w--full">
  //       <Spinner size="lg" />
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="d--flex gap--md h--full userDetails">
        <Sidebar userDetail={userDetail} />
        {isLoading ? (
          <div className="d--flex align-items--center justify-content--center h--full w--full">
            <Spinner size="lg" />
          </div>
        ) : (
          <Outlet context={userDetail} />
        )}
      </div>
    </>
  );
};

export default UserDetails;

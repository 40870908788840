import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const PieChart = ({
  title = 'HRA by Carriers',
  type = 'pie',
  dataPoints = [
    { y: 18, label: 'Direct' },
    { y: 49, label: 'Organic Search' },
    { y: 9, label: 'Paid Search' },
    { y: 5, label: 'Referral' },
    { y: 19, label: 'Social' },
  ],
}) => {
  const options = {
    // exportEnabled: true,
    animationEnabled: true,
    title: {
      text: title,
    },
    data: [
      {
        type,
        startAngle: 75,
        toolTipContent: '<b>{label}</b>: {y}%',
        showInLegend: 'true',
        legendText: '{label}',
        indexLabelFontSize: 16,
        indexLabel: '{label} - {y}%',
        dataPoints,
      },
    ],
  };
  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default PieChart;

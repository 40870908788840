import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const dataPoints = [
  { label: 'Mon', totalAgencyUser: 0, totalAgent: 0 ,totalMember:0,totalCarrier:0},
  { label: 'Tue', totalAgencyUser: 0, totalAgent: 0 ,totalMember:0,totalCarrier:0},
  { label: 'Wed', totalAgencyUser: 0, totalAgent: 0 ,totalMember:0,totalCarrier:0},
  { label: 'Thu', totalAgencyUser: 0, totalAgent: 0 ,totalMember:0,totalCarrier:0},
  { label: 'Fri', totalAgencyUser: 0, totalAgent: 0 ,totalMember:0,totalCarrier:0},
  { label: 'Sat', totalAgencyUser: 0, totalAgent: 0 ,totalMember:0,totalCarrier:0},
  { label: 'Sun', totalAgencyUser: 0, totalAgent: 0 ,totalMember:0,totalCarrier:0},
];


const UserGraph = ({ graphData }) => {
  const options = {
    animationEnabled: true,
    title: {
      text: graphData?.title ? graphData?.title : 'Yearly Users Summary',
      fontSize: 35,
      fontWeight: 'semibold',
    },
    toolTip: {
      shared: true,
    },
    axisY: {
      gridColor: 'lightgray',
      gridThickness: 1,
    },
    data: [
      {
        type: 'column',
        name: 'Agent',
        showInLegend: true,
        dataPoints: (graphData?.data ? graphData?.data : dataPoints).map(
          (point) => ({
            label: point.label,
            y: point.totalAgent,
          }),
        ),
        color: '#86A788',
      },
      {
        type: 'column',
        name: 'Agency',
        showInLegend: true,
        dataPoints: (graphData?.data ? graphData?.data : dataPoints).map(
          (point) => ({
            label: point.label,
            y: point.totalAgencyUser,
          }),
        ),
        color: '#007193',
      },
      {
        type: 'column',
        name: 'Consumer',
        showInLegend: true,
        dataPoints: (graphData?.data ? graphData?.data : dataPoints).map(
          (point) => ({
            label: point.label,
            y: point.totalMember,
          }),
        ),
        color: '#79D7BE',
      },
      {
        type: 'column',
        name: 'Carrier',
        showInLegend: true,
        dataPoints: (graphData?.data ? graphData?.data : dataPoints).map(
          (point) => ({
            label: point.label,
            y: point.totalCarrier,
          }),
        ),
        color: '#ff4e43',
      },
   
   
    ],
  };

  return <CanvasJSChart options={options} />;
};

export default UserGraph;

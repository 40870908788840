import { useState } from 'react';
import useIcons from '../../../../../assets/icons/useIcons';
import useDashBoardLogin from '../../../../../hooks/useDashBoardLogin';
import { useNavigate, useParams } from 'react-router-dom';
import { convertDateFunction } from '../../../../../helpers/functions';
import TableManager from '../../../../../components/TableManager';
import { agentConsumerList, getConsumerAgentList } from '../../../../../api';

const filterInitialValues = {
  filter: [
    {
      key: 'account_number',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter account number',
      inputType: 'number',
      inputLabel: 'Account number',
    },
    {
      key: 'email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter Email',
      inputType: 'email',
      inputLabel: 'Email',
    },
  ],
};

const initialEditInstance = { isEdit: false, editData: {} };
export default function ConsumerAgent() {
  const { id } = useParams();

  const { EyeOffIcon, EyeIcon } = useIcons();

  const { SuccessOutlineIcon, CrossIcon } = useIcons();

  const [addMember, setAddMember] = useState(false);

  const { mutate: mutateDashBoardLogin } = useDashBoardLogin();

  const [editUserInstance, setEditUserInstance] = useState(initialEditInstance);
  const { MoreVIcon } = useIcons();
  const navigate = useNavigate();

  const handleDropListClick = async ({ value }, data) => {
    const { _id } = data || {};

    switch (value) {
      case 'edit':
        // setEditUserInstance({ isEdit: true, editData: data });
        // setAddMember(true);
        break;
      case 'change-status':
        // handleChangeStaus(data);
        break;
      case 'view':
        // navigate(`/user-info/${data?.uuid}/profile`);
        break;
      case 'go_to_dashboard':
        mutateDashBoardLogin(_id);

        break;

      default:
        break;
    }
  };

  const handleUserDetailsModal = (data) => {
    // navigate(`/user-info/${data?.uuid}/dashboard`);
    navigate(`/user-info/${data?.uuid}/profile`);
  };
  const agentStatusLookup = {
    A: { text: 'Accepted', class: 'text--success' },
    I: { text: 'Pending', class: 'text--warning' },
    R: { text: 'Released', class: 'text--info' },
    D: { text: 'Declined', class: 'text--danger' },
  };
  const columns = [
    {
      accessorKey: 'agentDetail',
      header: () => '',
      columns: [
        {
          accessorKey: 'agentDetail.firstName',
          header: () => 'Name',
          cell: ({ row }) => {
            const element = row?.original?.agentDetail;
            return `${element?.firstName} ${element?.lastName}`;
          },
        },
        {
          accessorKey: 'agentDetail.email',
          header: () => 'Email',
        },
        {
          accessorKey: 'agentDetail.phone',
          header: () => 'Phone',
          meta: {
            textAlign: 'center',
          },
        },

        {
          accessorKey: 'agentDetail.NPN',
          header: () => 'NPN',
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 4,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'submittedDate',
      header: () => <span className="font--sm font--600">Signed Date</span>,
      columns: [
        {
          accessorKey: 'agentDetail.NPN',
          header: () => 'Consent',
          cell: (props) => {
            const rowData = props?.row?.original;
            // if (rowData?.consumerInvitationStatus === 'I') {
            //   return '---';
            // }
            if (rowData.signedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(rowData.signedDate)}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        // `/view-signed-pdf?${rowData?.agreementUuid}?type=CMS`,
                        `/view-document/${rowData?.agreementUuid}?docType=CMS`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }
            if (
              rowData?.consumerInvitationStatus === 'A' &&
              rowData?.submittedDate
            ) {
              return (
                <span
                  className="text--danger font--600 c--pointer"
                  onClick={() => {
                    navigate(
                      `/signature-document/${rowData?.agreementUuid}?docType=CMS`,
                    );
                  }}
                >
                  Click to Sign
                </span>
              );
            }
            return (
              <span className=" font--600  c--not-allowed opacity-5 ">---</span>
            );
          },
          meta: {
            textAlign: 'center',
          },
        },
        {
          accessorKey: 'agentDetail.NPN',
          header: () => 'Eligibility',
          cell: (props) => {
            const rowData = props?.row?.original;
            // if (rowData?.consumerInvitationStatus === 'I') {
            //   return '---';
            // }
            if (rowData.eligibilitySignedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(rowData?.eligibilitySignedDate)}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        // `/view-signed-pdf?${rowData?.eligibilityAgreementUuid}?type=ELIGIBILITY`,
                        `/view-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }

            if (
              rowData?.consumerInvitationStatus === 'A' &&
              rowData?.signedDate &&
              rowData?.eligibilitySubmittedDate
            ) {
              return (
                <span
                  className="text--danger font--600 c--pointer"
                  onClick={() => {
                    navigate(
                      `/signature-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                    );
                  }}
                >
                  Click to Sign
                </span>
              );
            }
            return (
              <span className=" font--600  c--not-allowed opacity-5 ">---</span>
            );
          },
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'eligibilitySubmittedDate',
      header: () => '',
      columns: [
        {
          accessorKey: 'consumerInvitationStatus',
          header: () => 'Status',
          cell: ({ getValue }) => {
            return (
              <span className={agentStatusLookup?.[getValue()]?.class}>
                {agentStatusLookup?.[getValue()]?.text}
              </span>
            );
          },
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 1,
        textAlign: 'center',
      },
    },
  ];
  return (
    <div className="w--full">
      <TableManager
        {...{
          fetcherKey: 'getConsumerAgentList',
          fetcherFn: getConsumerAgentList,
          columns,
          name: 'Agent',
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          selectCBForTableData: (tblData) => tblData?.data?.data?.data,
          extraParams: { uniqueCodeId: id },
          fullTableClass: 'table--responsive--search-scroll--full',
        }}
      />
    </div>
  );
}

import { useState } from 'react';
import useIcons from '../../../../../assets/icons/useIcons';
import useDashBoardLogin from '../../../../../hooks/useDashBoardLogin';
import { useNavigate, useParams } from 'react-router-dom';
import {
  convertDateFunction,
  formatDate,
} from '../../../../../helpers/functions';
import Dropdown from '../../../../../components/Dropdown';
import TableManager from '../../../../../components/TableManager';
import {
  agentClaimList,
  agentConsumerList,
  agentInviteList,
  getConsumer,
} from '../../../../../api';

const filterInitialValues = {
  filter: [
    {
      key: 'account_number',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter account number',
      inputType: 'number',
      inputLabel: 'Account number',
    },
    {
      key: 'email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter Email',
      inputType: 'email',
      inputLabel: 'Email',
    },
  ],
};

const initialEditInstance = { isEdit: false, editData: {} };
export default function AgentInvite() {
  const { id } = useParams();

  const { SuccessOutlineIcon, CrossIcon } = useIcons();

  const [addMember, setAddMember] = useState(false);

  const { mutate: mutateDashBoardLogin } = useDashBoardLogin();

  const [editUserInstance, setEditUserInstance] = useState(initialEditInstance);
  const { MoreVIcon } = useIcons();
  const navigate = useNavigate();

  const handleDropListClick = async ({ value }, data) => {
    const { _id } = data || {};

    switch (value) {
      case 'edit':
        // setEditUserInstance({ isEdit: true, editData: data });
        // setAddMember(true);
        break;
      case 'change-status':
        // handleChangeStaus(data);
        break;
      case 'view':
        // navigate(`/user-info/${data?.uuid}/profile`);
        break;
      case 'go_to_dashboard':
        mutateDashBoardLogin(_id);

        break;

      default:
        break;
    }
  };

  const handleUserDetailsModal = (data) => {
    // navigate(`/user-info/${data?.uuid}/dashboard`);
    navigate(`/user-info/${data?.uuid}/profile`);
  };
  const agentStatusLookup = {
    A: { text: 'Accepted', class: 'text--success' },
    I: { text: 'Pending', class: 'text--warning' },
    R: { text: 'Released', class: 'text--info' },
    D: { text: 'Declined', class: 'text--danger' },
  };

  const columns = [
    {
      accessorKey: 'agencyDetail.agencyName',
      header: () => 'Agency Name',
    },
    {
      accessorKey: 'agencyDetail.agencyPhone',
      header: () => 'Agency Phone',
    },
    {
      accessorKey: 'agencyDetail.agencyEmail',
      header: () => 'Agency Email',
    },
    {
      accessorKey: 'agencyDetail.agencyNPN',
      header: () => 'Agency NPN',
    },
    {
      accessorKey: 'createdAt',
      header: () => 'Invite date',
      cell: ({ getValue }) => convertDateFunction(getValue()),
    },
    {
      // accessorKey: 'status',
      accessorKey: 'agentInvitationStatus',
      header: () => 'Status',
      cell: ({ getValue }) => {
        return (
          <span className={agentStatusLookup?.[getValue()]?.class}>
            {agentStatusLookup?.[getValue()]?.text}
          </span>
        );
      },
    },
  ];

  return (
    <div className="w--full">
      <TableManager
        {...{
          fetcherKey: 'agentInviteList',
          fetcherFn: agentInviteList,
          columns,
          name: 'Invite',
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          selectCBForTableData: (tblData) => tblData?.data?.data?.data,
          extraParams: { uniqueCodeId: id },
          fullTableClass: 'table--responsive--search-scroll--full',
        }}
      />
    </div>
  );
}

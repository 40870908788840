import React, { useEffect, useState } from 'react';
import {
  addictionUseArr,
  diagnosedOptions,
  dietOptions,
  exerciseArr,
  exerciseOptions,
  familyDiagnosedOptions,
  genderArr,
  healthPrioritiesOptions,
  marStatusArr,
  pastYearOptions,
  psaTestOptions,
  relationArr,
  sleepCycleOptions,
  stressLevelOptions,
  timeSpanOptions,
  vaccinationOptions,
  wellnessOptions,
  yesNoQuestionsArr,
  yesOrNo,
} from '../../../constants';
import moment from 'moment';
import { useAuth } from '../../../../../hooks/useAuth';
import { AGENCY_USER, AGENT, MEMBER } from '../../../../../helpers/constants';
import FormInput from '../../../../../components/FormInput';
import FormSelect from '../../../../../components/FormSelect';
import PhoneInput from 'react-phone-number-input/react-hook-form';

const HRAFormView = ({
  register,
  errors,
  watch,
  setValue,
  index,
  rowData,
  control,
}) => {
  const { user } = useAuth();
  const userRole = user?.role || localStorage.getItem('nph-agency-user-role');

  let [watchDob] = watch([
    `member.${index}.data.personal_demographic_info.date_of_birth`,
  ]);

  const [relationList, setRelationList] = useState(relationArr);

  const targetDate = moment(watchDob, 'YYYY-MM-DD');
  const yearsGap = targetDate.diff(moment(), 'years');

  const handleCheckBox = (key, value) => {
    const sendViaWatch = watch(`member.${index}.data.${key}`);
    console.log('🚀 ~ handleCheckBox ~ sendViaWatch:', sendViaWatch);
    const updatedValues = sendViaWatch?.includes(value)
      ? sendViaWatch.filter((v) => v !== value)
      : [...(sendViaWatch ?? []), value];

    setValue(`member.${index}.data.${key}`, updatedValues, {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (watch(`member.${index}.relation`)) {
      const existingRelations = watch('member').map((item) =>
        item.relation?.trim(),
      );
      const filteredArr = relationArr.filter(
        (item) =>
          !existingRelations.includes(item.value) ||
          watch(`member.${index}.relation`) === item.value,
      );
      setRelationList(filteredArr);
    }
  }, [index, watch('member')]);

  return (
    <>
      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="d--flex h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md justify-content--between">
          <div className="font--md font--600 ">
            Personal and Demographic Information
          </div>

          <div className="d--flex gap--md">
            {[AGENCY_USER, AGENT].includes(userRole) && rowData?.uuId && (
              <div
                className="font--sm font--500 c--pointer text--primary"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_PORTAL_URL}hra-consent-sign-doc/${rowData?.uuId}`,
                  );
                }}
              >
                View Consent
              </div>
            )}
            {[MEMBER].includes(userRole) &&
              rowData?.uuId &&
              rowData?.documentStatus === 'SIGNED' && (
                <div
                  className="font--sm font--500 c--pointer text--primary"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_PORTAL_URL}hra-consent-sign-doc/${rowData?.uuId}`,
                    );
                  }}
                >
                  View Consent
                </div>
              )}
            {[MEMBER].includes(userRole) &&
              rowData?.formConfirmUuId &&
              rowData?.formDocumentStatus === 'SIGNED' && (
                <div
                  className="font--sm font--500 c--pointer text--primary"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_PORTAL_URL}hra-form-view-doc/${rowData?.formConfirmUuId}`,
                    );
                  }}
                >
                  {' '}
                  View HRA Form
                </div>
              )}
            {[MEMBER].includes(userRole) &&
              rowData?.formConfirmUuId &&
              rowData?.formDocumentStatus === 'NO' && (
                <div
                  className="font--sm font--500 c--pointer text--primary"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_PORTAL_URL}hra-form-sign-doc/${rowData?.formConfirmUuId}`,
                    );
                  }}
                >
                  {' '}
                  Sign HRA Form
                </div>
              )}
          </div>
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--flex w--full gap--lg">
            <div className="w--full d--flex">
              <FormInput
                disabled
                extraClasses="c--not-allowed"
                label="Name"
                placeholder="Enter Name"
                {...register(
                  `member.${index}.data.personal_demographic_info.name`,
                )}
                error={errors?.personal_demographic_info?.name?.message}
              />
            </div>
            <div className="w--full d--flex">
              <FormInput
                disabled
                extraClasses="c--not-allowed"
                label="Date of birth"
                placeholder="Date of birth"
                type="date"
                {...register(
                  `member.${index}.data.personal_demographic_info.date_of_birth`,
                )}
                max={new Date().toISOString().split('T')[0]}
                error={
                  errors?.personal_demographic_info?.date_of_birth?.message
                }
              />
            </div>
            <div className="w--full d--flex">
              <FormSelect
                disabled
                extraClasses="c--not-allowed"
                label="Gender"
                placeholder="Select Gender"
                options={genderArr}
                error={errors?.personal_demographic_info?.gender?.message}
                {...register(
                  `member.[${index}].data.personal_demographic_info.gender`,
                )}
              />
            </div>

            <div className="w--full d--flex">
              <FormSelect
                disabled
                extraClasses="c--not-allowed"
                label="Relation"
                placeholder="Select relationship"
                options={relationList}
                error={errors?.personal_demographic_info?.relation?.message}
                {...register(
                  `member.${index}.data.personal_demographic_info.relation`,
                )}
                onChange={(e) => {
                  setValue(`member.${index}.relation`, e.target.value);

                  const existingRelations = watch('member').map((item) =>
                    item.relation?.trim(),
                  );
                  const filteredArr = relationArr.filter(
                    (item) =>
                      !existingRelations.includes(item.value) ||
                      watch(`member.${index}.relation`) === item.value,
                  );
                  setRelationList(filteredArr);
                }}
              />
            </div>
            <div className="w--full d--flex">
              <FormSelect
                disabled
                extraClasses="c--not-allowed"
                label=" Marital Status"
                placeholder="Select  Marital Status"
                options={marStatusArr}
                error={
                  errors?.personal_demographic_info?.marital_status?.message
                }
                {...register(
                  `member.${index}.data.personal_demographic_info.marital_status`,
                )}
              />
            </div>
          </div>
          <div className="d--flex w--full gap--lg">
            <div className="w--full d--flex">
              <FormInput
                disabled
                extraClasses="c--not-allowed"
                label="Occupation"
                placeholder="Enter Occupation"
                error={errors?.personal_demographic_info?.occupation?.message}
                {...register(
                  `member.${index}.data.personal_demographic_info.occupation`,
                )}
                required={false}
              />
            </div>
            {/* <div className="w--full d--flex">
              <FormSelect
                  disabled
                extraClasses="c--not-allowed"
                label="Primary Care Provider"
                placeholder="Select Provider"
                options={
                  providerList && providerList?.length > 0
                    ? providerList?.map((val) => ({
                        label: val.name,
                        value: val?.npi,
                      }))
                    : []
                }
                error={
                  errors?.personal_demographic_info?.primary_care_provider
                    ?.message
                }
                {...register(
                  `member.${index}.data.personal_demographic_info.primary_care_provider`,
                )}
              />

            </div> */}
            <FormInput
              label="Email"
              placeholder="Enter Email"
              {...register(
                `member.${index}.data.personal_demographic_info.email`,
              )}
              error={errors?.personal_demographic_info?.email?.message}
              extraClasses="c--not-allowed"
              disabled
            />
            <div className="w--full">
              <label
                className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                  errors?.personal_demographic_info?.phone?.message
                    ? 'text--danger'
                    : 'text--grey'
                }`}
              >
                {errors?.personal_demographic_info?.phone?.message ?? 'Phone'}
                <span className=" font--sm text--danger m--xs">*</span>
              </label>
              <PhoneInput
                disabled
                name={`member.${index}.data.personal_demographic_info.phone`}
                control={control}
                defaultCountry={'US'}
                international={true}
                withCountryCallingCode={true}
                placeholder="Enter your phone"
                internationalIcon={() => (
                  <span>
                    <i className="fa fa-phone icon-circle bg-danger"></i>
                  </span>
                )}
                limitMaxLength={true}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  d--flex align-items--center c--not-allowed  ${
                  errors?.personal_demographic_info?.phone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                }`}
              />
            </div>

            <div className="w--full d--flex">
              <FormInput
                disabled
                extraClasses="c--not-allowed"
                label="Carrier"
                placeholder="Select Carrier"
                // options={
                //   carrierList && carrierList?.length > 0
                //     ? carrierList?.map((val) => ({
                //         label: val.name,
                //         value: val?.id,
                //       }))
                //     : []
                // }
                // error={errors?.personal_demographic_info?.carrierId?.message}
                {...register(
                  `member.${index}.data.personal_demographic_info.carrierName`,
                )}
              />
            </div>
            <div className="w--full"></div>
          </div>
        </div>
      </div>

      {yearsGap >= -18 || rowData?.documentStatus === 'SIGNED' ? (
        <>
          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex  p-l--md p-r--md flex--column justify-content--center">
              Have you ever been diagnosed with any of the following conditions?
              <div className="font--2sm font--black-600 font--400">
                (Select all that apply)
              </div>
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--grid grid--4 w--full gap--lg">
                {diagnosedOptions.map((val) => {
                  return (
                    <div className="w--full d--flex" key={val?.value + index}>
                      <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed form--control"
                          type="checkbox"
                          value={val?.value}
                          onChange={(e) => {
                            const { value } = e.target;

                            handleCheckBox(`diagnosed_with_conditions`, value);

                            if (value === 'others') {
                              setValue(
                                `member.${index}.data.diagnosed_with_conditions_otherValue`,
                                '',
                              );
                            }
                          }}
                          checked={watch(
                            `member.${index}.data.diagnosed_with_conditions`,
                          )?.includes(val?.value)}
                        />
                        {val?.label}
                      </label>
                    </div>
                  );
                })}
                <div className="w--full d--flex ">
                  {watch(
                    `member.${index}.data.diagnosed_with_conditions`,
                  )?.includes('others') ? (
                    <FormInput
                      disabled
                      extraClasses="c--not-allowed"
                      error={
                        errors?.diagnosed_with_conditions_otherValue?.message
                      }
                      {...register(
                        `member.${index}.data.diagnosed_with_conditions_otherValue`,
                      )}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="d--flex  gap--md w--full">
            <div className="d--flex flex--column gap--md w--full">
              {yesNoQuestionsArr.map((item) => {
                return (
                  <div
                    key={item.label}
                    className="d--flex gap--xl align-items--center justify-content--between  radius--md p-t--md p-b--md w--full"
                  >
                    <div className="label--control font--sm font--700 text--black-600 d--flex gap--sm w--full">
                      {item.label}
                    </div>
                    <div className="w--full d--flex gap--md   ">
                      <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed"
                          type="radio"
                          id={item?.value}
                          value={yesOrNo.yes}
                          onChange={(e) => {
                            setValue(
                              `member.${index}.data.${item?.value}`,
                              e.target.checked,
                            );
                          }}
                          checked={watch(`member.${index}.data.${item?.value}`)}
                        />
                        Yes
                      </label>
                      <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed"
                          type="radio"
                          value={yesOrNo.no}
                          id={item?.value}
                          onChange={(e) => {
                            setValue(
                              `member.${index}.data.${item?.value}`,
                              !e.target.checked,
                            );
                          }}
                          checked={
                            !watch(`member.${index}.data.${item?.value}`)
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              Has anyone in your immediate family (parents, siblings) been
              diagnosed with any of the following conditions?
            </div>
            <div className="d--grid grid--4 w--full gap--lg p--lg h-min--100">
              {familyDiagnosedOptions.map((val) => {
                return (
                  <div className="w--full d--flex" key={val?.value + index}>
                    <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                      <input
                        disabled
                        className="c--not-allowed"
                        type="checkbox"
                        value={val?.value}
                        onChange={(e) => {
                          const { value } = e.target;
                          handleCheckBox(
                            'family_diagnosed_with_conditions',
                            value,
                          );
                        }}
                        checked={watch(
                          `member.${index}.data.family_diagnosed_with_conditions`,
                        )?.includes(val?.value)}
                      />
                      {val?.label}
                    </label>
                  </div>
                );
              })}
              <div className="w--full d--flex ">
                {watch(
                  `member.${index}.data.family_diagnosed_with_conditions`,
                )?.includes('others') && (
                  <FormInput
                    disabled
                    extraClasses="c--not-allowed"
                    error={
                      errors?.family_diagnosed_with_conditions_other?.message
                    }
                    {...register(
                      `member.${index}.data.family_diagnosed_with_conditions_other`,
                    )}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              Do you currently use any of the following?
            </div>
            <div className="w--full d--flex flex--column  gap--md p--lg h-min--100">
              <div className="w--full d--flex  gap--3xl ">
                {addictionUseArr.map((item) => {
                  return (
                    <div
                      key={item.label + index}
                      className="d--flex gap--xl align-items--center justify-content--between  radius--md p-t--md p-b--md w--full"
                    >
                      <div className="label--control font--sm font--700 text--black-600 d--flex gap--sm w--full">
                        {item.label}
                      </div>
                      <div className="w--full d--flex gap--md   ">
                        <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                          <input
                            disabled
                            className="c--not-allowed"
                            type="radio"
                            id={item?.value}
                            value={yesOrNo.yes}
                            onChange={(e) => {
                              setValue(
                                `member.${index}.data.substance_use.${item?.value}`,
                                e.target.checked,
                              );
                            }}
                            checked={watch(
                              `member.${index}.data.substance_use.${item?.value}`,
                            )}
                          />
                          Yes
                        </label>
                        <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                          <input
                            disabled
                            className="c--not-allowed"
                            type="radio"
                            value={yesOrNo.no}
                            id={item?.value}
                            onChange={(e) => {
                              setValue(
                                `member.${index}.data.substance_use.${item?.value}`,
                                e.target.checked,
                              );
                            }}
                            checked={
                              !watch(
                                `member.${index}.data.substance_use.${item?.value}`,
                              )
                            }
                          />
                          No
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              On average, how many hours of sleep do you get per night?
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--flex w--full gap--lg">
                {sleepCycleOptions.map((item) => {
                  return (
                    <div key={item.label + index} className="w--full d--flex">
                      <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed"
                          type="radio"
                          value={item.value}
                          checked={
                            item.value ===
                            watch(`member.${index}.data.sleep_hours`)
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(`member.${index}.data.sleep_hours`, value);
                          }}
                        />
                        {item.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              How often do you exercise?
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--grid grid--4 w--full gap--lg">
                {exerciseOptions.map((item) => {
                  return (
                    <div key={item.label + index} className="w--full d--flex">
                      <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed"
                          type="radio"
                          value={item.value}
                          checked={
                            item.value ===
                            watch(`member.${index}.data.exercise_schedule`)
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(
                              `member.${index}.data.exercise_schedule`,
                              value,
                            );
                          }}
                        />
                        {item.label}
                      </label>
                    </div>
                  );
                })}
                <div className="w--full d--flex ">
                  <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="checkbox"
                      onChange={(e) => {
                        const { checked } = e.target;
                        setValue(`member.${index}.data.typeofEx`, checked);
                        setValue(`member.${index}.data.typeofExValue`, '');
                      }}
                      checked={watch(`member.${index}.data.typeofEx`)}
                    />
                    Type of exercise
                  </label>
                </div>
                <div className="w--full d--flex ">
                  {watch(`member.${index}.data.typeofEx`) && (
                    <FormSelect
                      disabled
                      extraClasses="c--not-allowed"
                      placeholder="Select type of exercise"
                      error={errors?.typeofExValue?.message}
                      options={exerciseArr}
                      {...register(`member.${index}.data.typeofExValue`)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              On average, how would you rate your daily stress levels?
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--flex w--full gap--lg">
                {stressLevelOptions.map((item) => {
                  return (
                    <div key={item.label + index} className="w--full d--flex">
                      <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed"
                          type="radio"
                          value={item.value}
                          checked={
                            item.value ===
                            watch(`member.${index}.data.stress_level`)
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(
                              `member.${index}.data.stress_level`,
                              value,
                            );
                          }}
                        />
                        {item.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              How would you rate your current diet?
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--flex w--full gap--lg">
                {dietOptions.map((item) => {
                  return (
                    <div key={item.label + index} className="w--full d--flex">
                      <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed"
                          type="radio"
                          value={item.value}
                          checked={
                            item.value ===
                            watch(`member.${index}.data.current_diet_rating`)
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(
                              `member.${index}.data.current_diet_rating`,
                              value,
                            );
                          }}
                        />
                        {item.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              When was your last physical exam?
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--flex w--full gap--lg">
                {pastYearOptions.map((item) => {
                  return (
                    <div key={item.label + index} className="w--full d--flex">
                      <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed"
                          type="radio"
                          value={item.value}
                          checked={
                            item.value ===
                            watch(`member.${index}.data.last_physical_exam`)
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(
                              `member.${index}.data.last_physical_exam`,
                              value,
                            );
                          }}
                        />
                        {item.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              Have you received any of the following vaccinations in the past?
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--grid grid--4 w--full gap--lg">
                {vaccinationOptions.map((val) => {
                  return (
                    <div className="w--full d--flex" key={val?.value}>
                      <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed"
                          type="checkbox"
                          value={val?.value}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleCheckBox(
                              'vaccinations_received_in_past',
                              value,
                            );
                          }}
                          checked={watch(`
member.${index}.data.vaccinations_received_in_past`)?.includes(val?.value)}
                        />
                        {val?.label}
                      </label>
                    </div>
                  );
                })}
                <div className="w--full d--flex ">
                  {watch(
                    `member.${index}.data.vaccinations_received_in_past`,
                  )?.includes('others') && (
                    <FormInput
                      disabled
                      extraClasses="c--not-allowed"
                      error={
                        errors?.vaccinations_received_in_past_other?.message
                      }
                      {...register(
                        `member.${index}.data.vaccinations_received_in_past_other`,
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              For women only:
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--flex flex--column gap--sm">
                <div className="font--sm font--600 m-b--md">
                  When was your last mammogram?
                </div>
                <div className="d--flex w--full gap--lg">
                  {pastYearOptions.map((item) => {
                    return (
                      <div key={item.label + index} className="w--full d--flex">
                        <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                          <input
                            disabled
                            className="c--not-allowed"
                            type="radio"
                            value={item.value}
                            checked={
                              item.value ===
                              watch(`member.${index}.data.last_mammogram`)
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              setValue(
                                `member.${index}.data.last_mammogram`,
                                value,
                              );
                            }}
                          />
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="d--flex flex--column gap--sm">
                <div className="font--sm font--600 m-b--md">
                  When was your last Pap smear?
                </div>
                <div className="d--flex w--full gap--lg">
                  {pastYearOptions.map((item) => {
                    return (
                      <div key={item.label + index} className="w--full d--flex">
                        <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                          <input
                            disabled
                            className="c--not-allowed"
                            type="radio"
                            value={item.value}
                            checked={
                              item.value ===
                              watch(`member.${index}.data.last_pap_smear`)
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              setValue(
                                `member.${index}.data.last_pap_smear`,
                                value,
                              );
                            }}
                          />
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              For men only:
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--flex flex--column gap--sm">
                <div className="font--sm font--600 m-b--md">
                  Have you had a prostate exam or PSA test in the past?
                </div>
                <div className="d--flex w--full gap--lg">
                  {psaTestOptions.map((item) => {
                    return (
                      <div key={item.label + index} className="w--full d--flex">
                        <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                          <input
                            disabled
                            className="c--not-allowed"
                            type="radio"
                            value={item.value}
                            checked={
                              item.value ===
                              watch(`member.${index}.data.prostate_exam_psa`)
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              setValue(
                                `member.${index}.data.prostate_exam_psa`,
                                value,
                              );
                            }}
                          />
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="d--flex flex--column gap--md w--full">
            <div className="d--flex gap--2xl w--full">
              <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full">
                <div className="label--control font--sm font--700   text--black-600 d--flex gap--sm ">
                  Have you ever had a colonoscopy?
                </div>
                <div className="w--full d--flex gap--md w-max--150 justify-content--end ">
                  <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="radio"
                      value={'Yes'}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.had_colonoscopy`,
                          e.target.checked,
                        );
                      }}
                      checked={watch(`member.${index}.data.had_colonoscopy`)}
                    />
                    Yes
                  </label>
                  <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="radio"
                      value={'No'}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.had_colonoscopy`,
                          !e.target.checked,
                        );
                      }}
                      checked={!watch(`member.${index}.data.had_colonoscopy`)}
                    />
                    No
                  </label>
                </div>
              </div>
              <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full"></div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              Over the past 2 weeks, how often have you experienced any of the
              following?
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--flex flex--column gap--sm">
                <div className="font--sm font--600 m-b--md">
                  Feeling down, depressed, or hopeless
                </div>
                <div className="d--flex w--full gap--lg">
                  {timeSpanOptions.map((item) => {
                    return (
                      <div key={item.label + index} className="w--full d--flex">
                        <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                          <input
                            disabled
                            className="c--not-allowed"
                            type="radio"
                            value={item.value}
                            checked={
                              item.value ===
                              watch(
                                `member.${index}.data.past_two_week.feeling_down_depressed`,
                              )
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              setValue(
                                `member.${index}.data.past_two_week.feeling_down_depressed`,
                                value,
                              );
                            }}
                          />
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="d--flex flex--column gap--sm">
                <div className="font--sm font--600 m-b--md">
                  Little interest or pleasure in doing things
                </div>
                <div className="d--flex w--full gap--lg">
                  {timeSpanOptions.map((item) => {
                    return (
                      <div key={item.label + index} className="w--full d--flex">
                        <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                          <input
                            disabled
                            className="c--not-allowed"
                            type="radio"
                            value={item.value}
                            checked={
                              item.value ===
                              watch(
                                `member.${index}.data.past_two_week.little_please_in_things`,
                              )
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              setValue(
                                `member.${index}.data.past_two_week.little_please_in_things`,
                                value,
                              );
                            }}
                          />
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="d--flex flex--column gap--sm w--full">
            <div className="d--flex gap--sm flex--column w--full">
              <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full">
                <div className="label--control font--sm font--700   text--black-600 d--flex gap--sm ">
                  Have you ever been diagnosed with a mental health condition
                  (e.g., anxiety, depression, bipolar disorder, etc.)?
                </div>
                <div className=" d--flex gap--md w-max--250 ">
                  <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="radio"
                      value={'Yes'}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.diagnosed_with_mental_health_condition`,
                          e.target.checked,
                        );
                      }}
                      checked={watch(
                        `member.${index}.data.diagnosed_with_mental_health_condition`,
                      )}
                    />
                    Yes
                  </label>
                  <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="radio"
                      value={'No'}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.diagnosed_with_mental_health_condition`,
                          !e.target.checked,
                        );
                      }}
                      checked={
                        !watch(
                          `member.${index}.data.diagnosed_with_mental_health_condition`,
                        )
                      }
                    />
                    No
                  </label>
                </div>
              </div>
              <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full">
                <div className="label--control font--sm font--700   text--black-600 d--flex gap--sm ">
                  Do you currently see a mental health professional
                  (psychiatrist, therapist, counselor)?
                </div>
                <div className=" d--flex gap--md w-max--250 ">
                  <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="radio"
                      value={'Yes'}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.seeing_mental_health_professional`,
                          e.target.checked,
                        );
                      }}
                      checked={watch(
                        `member.${index}.data.seeing_mental_health_professional`,
                      )}
                    />
                    Yes
                  </label>
                  <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="radio"
                      value={'No'}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.seeing_mental_health_professional`,
                          !e.target.checked,
                        );
                      }}
                      checked={
                        !watch(
                          `member.${index}.data.seeing_mental_health_professional`,
                        )
                      }
                    />
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              What are your top health priorities?
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--flex w--full gap--lg">
                {healthPrioritiesOptions.map((val) => {
                  return (
                    <div className="w--full d--flex" key={val?.value + index}>
                      <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed"
                          type="checkbox"
                          value={val?.value}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleCheckBox('top_health_priorities', value);
                          }}
                          checked={watch(
                            `member.${index}.data.top_health_priorities`,
                          )?.includes(val?.value)}
                        />
                        {val?.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="d--flex flex--column  border-full--black-100 radius--md ">
            <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
              Are you interested in participating in wellness programs or
              receiving guidance on any of the following?
            </div>
            <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
              <div className="d--grid grid--4 w--full gap--lg">
                {wellnessOptions.map((val) => {
                  return (
                    <div className="w--full d--flex" key={val?.value + index}>
                      <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                        <input
                          disabled
                          className="c--not-allowed"
                          type="checkbox"
                          value={val?.value}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleCheckBox(
                              'interests_in_wellness_programs',
                              value,
                            );
                          }}
                          checked={watch(
                            `member.${index}.data.interests_in_wellness_programs`,
                          )?.includes(val?.value)}
                        />
                        {val?.label}
                      </label>
                    </div>
                  );
                })}
                <div className="w--full d--flex ">
                  {watch(
                    `member.${index}.data.interests_in_wellness_programs`,
                  )?.includes('others') && (
                    <FormInput
                      disabled
                      extraClasses="c--not-allowed"
                      error={
                        errors?.interests_in_wellness_programs_other?.message
                      }
                      {...register(
                        `member.${index}.data.interests_in_wellness_programs_other`,
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d--flex flex--column gap--md w--full">
            <div className="d--flex flex--column gap--sm w--full">
              <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full">
                <div className="label--control font--sm font--700   text--black-600 d--flex gap--sm ">
                  Do you consent to your health information being used to
                  provide personalized care recommendations and interventions?
                </div>
                <div className="w--full d--flex gap--md w-max--150 justify-content--end ">
                  <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="radio"
                      value={'Yes'}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.health_info_usage_permission`,
                          e.target.checked,
                        );
                      }}
                      checked={watch(
                        `member.${index}.data.health_info_usage_permission`,
                      )}
                    />
                    Yes
                  </label>
                  <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="radio"
                      value={'No'}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.health_info_usage_permission`,
                          !e.target.checked,
                        );
                      }}
                      checked={
                        !watch(
                          `member.${index}.data.health_info_usage_permission`,
                        )
                      }
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full">
                <div className="label--control font--sm font--700   text--black-600 d--flex gap--sm ">
                  Do you consent to being contacted by our care management team
                  to discuss your health assessment and potential care options?
                </div>
                <div className="w--full d--flex gap--md w-max--150 justify-content--end ">
                  <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="radio"
                      value={'Yes'}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.care_management_consent`,
                          e.target.checked,
                        );
                      }}
                      checked={watch(
                        `member.${index}.data.care_management_consent`,
                      )}
                    />
                    Yes
                  </label>
                  <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      disabled
                      className="c--not-allowed"
                      type="radio"
                      value={'No'}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.care_management_consent`,
                          !e.target.checked,
                        );
                      }}
                      checked={
                        !watch(`member.${index}.data.care_management_consent`)
                      }
                    />
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {rowData?.formDocumentStatus === 'SIGNED' ? (
        <div className="d--flex justify-content--end w--full align-items--center">
          <div className="d--flex justify-content--between w--full align-items--center w-max--300">
            <div className="font--sm font--600 text--black-600">Signature:</div>
            <div className="d--flex justify-content--center gap--md w--full border--dashed border-full--black-200 w-max--200 p-r--lg radius--sm h-min--60">
              <img src={rowData?.formData?.SIGNATURE} alt="sign" width={150} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HRAFormView;

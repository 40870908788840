import React, { useState } from 'react';
import FormInput from '../../components/FormInput';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../components/Button';
import useLogin from '../../hooks/useLogin';
import useIcons from '../../assets/icons/useIcons';
// import konsentifyLogo from '../../assets/images/konsentify-logo.png';
import ConxentLogo from '../../assets/images/conxent-logo.png';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

export default function Login() {
  const [visibilityToggle, setvisibilityToggle] = useState(true);
  const { mutate, isLoading } = useLogin();
  const { EyeOffIcon, EyeIcon } = useIcons();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  async function onSubmit(values) {
    mutate(values);
  }
  return (
    <div className="w--full d--flex flex--column gap--md">
      <div className="w--full gap--md d--flex flex--column align-items--center justify-content--center">
        <div className="w--full d--flex justify-content--center">
          <img src={ConxentLogo} alt="nextereLogo" className="w-max--150 " />
        </div>
        <div className="w--full d--flex gap--xs flex--column justify-content--center align-items--center">
          <h3 className="font--md"> Admin Login</h3>

          <h5 className="font--md font--400 text--black-600">
            Login with Email address
          </h5>
        </div>
      </div>

      <form
        className="w--full d--flex flex--column gap--md"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <FormInput
              {...field}
              type="input"
              label="Email"
              placeholder="Enter your email address"
              error={errors?.email?.message}
              height="50"
            />
          )}
        />
        <div className="form-group mb-2 position-relative">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <div className="position-relative">
                <label
                  className={`label--control font--sm font--500 m-b--xs text--black d--flex ${
                    errors?.password?.message ? 'text--red' : 'text--black'
                  }`}
                >
                  {errors?.password?.message ?? 'Password'}
                </label>
                <div className="w--full position--relative">
                  <FormInput
                    {...field}
                    type={visibilityToggle ? 'password' : 'text'}
                    placeholder="Enter your password"
                    id="password"
                    autoComplete="false"
                    height="50"
                  />

                  <div
                    className="pointer svg--sm position--absolute top--15 right--10 translate-middle-y inputIcon"
                    onClick={() => setvisibilityToggle(!visibilityToggle)}
                  >
                    {visibilityToggle ? (
                      <EyeIcon width={20} height={20} />
                    ) : (
                      <EyeOffIcon width={20} height={20} />
                    )}
                  </div>
                </div>
              </div>
            )}
          />
        </div>

        <div className="form-group w--full m-t--md">
          <Button
            btnClasses="btn font--md font--600"
            type="submit"
            disabled={isSubmitting || isLoading}
            size="lg"
            variant="primary"
            color="black"
          >
            {isLoading ? 'Please wait...' : 'Login'}
          </Button>
        </div>
      </form>
    </div>
  );
}

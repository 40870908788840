import React, { useEffect, useState } from 'react';
import PasswordInput from '../../components/PasswordInput';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { npn, requiredFieldFunction, ssn } from '../../helpers/yupValidations';
import { getObjectLength, submitBtnTxt } from '../../helpers/functions';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAlert } from '../../hooks/useAlert';
import { getNIPRAuth, NIPRAuth, NIPRAuthTest } from '../../api';

const validationSchema = yup.object().shape({
  username: yup.string().required('User name is required'),
  // .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Agency name cannot contain numbers'),
  password: requiredFieldFunction('Password'),
  NPN: npn,
  SSN: ssn,
});
const initialValues = {
  username: '',
  password: '',
  NPN: '',
  SSN: '',
  type: 'TEST_AUTHORIZATION',
};
const NIPR = () => {
  const { showAlert } = useAlert();

  const [{ username, password, type }, setState] = useState({
    username: '',
    password: '',
    type: 'AUTHORIZATION',
  });
  const [response, setResponse] = useState(null);

  const { data, refetch } = useQuery({
    queryKey: 'getNIPRAuth',
    queryFn: getNIPRAuth,
    select: (data) => data?.data?.data,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: NIPRAuth,
    mutationKey: 'NIPRAuth',
    onSuccess: ({ data }) => {
      refetch();
      showAlert({
        type: 'success',
        message: data?.data?.message ?? 'Password save sucessfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ? error?.message : 'unexpected error';
      showAlert({
        type: 'warning',
        message: errMsg,
      });
    },
  });

  const { mutate: mutateTest, isLoading: testLoading } = useMutation({
    mutationFn: NIPRAuthTest,
    mutationKey: 'NIPRAuthTest',
    onSuccess: ({ data }) => {
      setResponse(data?.data);
    },
    onError: (error) => {
      const errMsg = error?.message ? error?.message : 'unexpected error';
      setResponse(errMsg);
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  const onSubmit = (_) => {
    mutateTest(_);
  };

  useEffect(() => {
    if (data && getObjectLength(data?.settingInformation)) {
      const { username, password } = data?.settingInformation || {};
      setState((p) => ({
        ...p,
        username,
        password,
      }));
    }
  }, [data]);

  return (
    <div className="d--flex align-items--center justify-content--center w--full h--full gap--md">
      <div className="w--full border-full--black-200 radius--md p--xl bg--white  h-min--150 d--flex flex--column gap--lg h--full">
        <div class="d--flex gap--sm align-items--center text--black font--md font--600  ">
          Update NIPR Username/Password
        </div>
        <div className="d--flex gap--md w--full align-items--end ">
          <FormInput
            label="User Name"
            value={username}
            onChange={(e) =>
              setState((p) => ({ ...p, username: e?.target?.value }))
            }
          />
          <PasswordInput
            label="NIPR Password"
            value={password}
            onChange={(e) =>
              setState((p) => ({ ...p, password: e?.target?.value }))
            }
          />
          <Button
            variant="primary"
            btnClasses="btn w-max--100 "
            size="md"
            onClick={() => {
              mutate({ username, password, type });
            }}
            disabled={!username || !password || isLoading}
          >
            Submit
          </Button>
        </div>
      </div>
      <form
        className="w--full border-full--black-200 radius--md p--xl bg--white  h-min--150 d--flex flex--column gap--lg h--full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div class="d--flex gap--sm align-items--center text--black font--md font--600  ">
          NIPR Test Authorization
        </div>

        <div className="d--flex gap--xl">
          <div className="w--full">
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="User Name"
                  error={errors?.username?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  label="Password"
                  error={errors?.password?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="d--flex gap--xl">
          <div className="w--full">
            <Controller
              name="NPN"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="NPN"
                  type="number"
                  error={errors?.NPN?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="SSN"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="SSN"
                  type="number"
                  error={errors?.SSN?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="d--flex gap--xl align-items--end">
          <div className="w--full"></div>
          <div className="w--full d--flex justify-content--end">
            <Button
              variant="primary"
              size="md"
              btnClasses="btn w-max--200"
              disabled={testLoading}
            >
              {submitBtnTxt(testLoading, 'Test NIPR')}
            </Button>
          </div>
        </div>
        {response && (
          <div
            className="w--full h--full h-max--200  overflow--auto bg--black text--white p--md"
            style={{ maxHeight: 'calc(100vh - 380px)' }}
          >
            <pre style={{ whiteSpace:"wrap" }}>
              <code>{response}</code>
              {/* <code>{JSON.stringify(response, null, 2)}</code> */}
            </pre>
          </div>
        )}
      </form>
    </div>
  );
};

export default NIPR;

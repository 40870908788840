import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Country } from 'country-state-city';
import PlanDetails from './PlanDetails';
import { formatDate } from '../../../../../helpers/functions';

const AgencyProfile = () => {
  const userDetail = useOutletContext();
  const {
    userInfo: {
      firstName,
      lastName,
      createdAt,
      phone,
      NPN,
      email,
      country,
      agentWebsite,
      isOwnAgency,
      ownAgencyWebsite,
      ownAgencyDetail: {
        agencyType,
        agencyName,
        agencyEmail,
        agencyPhone,
        agencyNPN,
        agencyEIN,
        address_one,
        address_two,
        state,
        city,
        zipCode,
      } = {},
      isAssociatedAgency,
      associatedAgencyDetail: {
        agencyName: asagencyName,
        agencyEmail: asagencyEmail,
        agencyPhone: asagencyPhone,
        agencyNPN: asagencyNPN,
        address_one: asaddress_one,
        address_two: asaddress_two,
        state: asstate,
        city: ascity,
        zipCode: aszipCode,
      } = {},
    } = {},
    planInfo = {},
  } = userDetail || {};

  return (
    <div className="d--flex w--full flex--column profilePage">
      <h4 className="font--xl font--600 m-b--md h-max--40 m-t--sm">Profile</h4>
      <div className="d--flex gap--2xl flex--column profilePageSec bg--white p--md radius--sm h--full overflow--auto box-shadow--xs">
        <div className="w--full d--flex flex--column gap--md">
          <div className="label--control font--md font--500 m-b--xs  h-min--44  d--flex gap--sm align-items--center text--black bg--black-50 border-full--black-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
            Personal Info<div class="d--flex"></div>
          </div>
          <div className="d--flex gap--xl">
            {/* <div className="w--full w-max--200 h-min--200 bg--black-50 radius--sm d--flex align-items--center justify-content--center"> */}
            {/* <Avatar
                image={
                  data?.profile_pic
                    ? `${process.env.REACT_APP_MEDIA_URL}${
                        parent_uuid || parent_uuid || uuid
                      }/profile?filename=${profile_pic}`
                    : null
                }
                name={firstName + lastName}
                size="100"
              /> */}
            {/* <UserIcon width={80} height={80} /> */}
            {/* </div> */}
            <div className="d--flex flex--column gap--md w--full">
              <div className="w--full d--flex gap--xl">
                <div className="d--flex flex--column gap--sm w--full">
                  <div className="font--sm font--600">Registration Date</div>
                  <div className="font--sm ">{formatDate(createdAt)}</div>
                </div>
              </div>
              <div className="d--flex flex--column gap--sm  w--full">
                <div className="font--sm font--600">Phone</div>
                <div className="font--sm ">{phone}</div>
              </div>
              <div className="d--flex flex--column gap--sm  w--full">
                <div className="font--sm font--600">Website</div>
                <div className="font--sm ">{agentWebsite || '---'}</div>
              </div>
            </div>
            <div className="d--flex flex--column gap--md w--full">
              <div className="w--full d--flex gap--xl">
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600">Full Name</div>
                  <div className="font--sm ">
                    {firstName} {lastName}
                  </div>
                </div>
              </div>
              <div className="d--flex flex--column gap--sm  w--full">
                <div className="font--sm font--600">Country:</div>
                <div className="font--sm ">
                  {Country.getCountryByCode(country)?.name}
                </div>
              </div>
            </div>
            <div className="d--flex flex--column gap--md w--full">
              <div className="w--full d--flex gap--xl">
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600">Email</div>
                  <div className="font--sm ">{email}</div>
                </div>
              </div>

              <div className="d--flex flex--column gap--sm  w--full">
                <div className="font--sm font--600">NPN:</div>
                <div className="font--sm ">{NPN}</div>
              </div>
            </div>
          </div>
        </div>

        {isOwnAgency && (
          <div className="w--full d--flex flex--column gap--md">
            <div className="label--control font--md font--500 m-b--xs  h-min--44  d--flex gap--sm align-items--center text--black bg--black-50 border-full--black-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
              Own Agency Info | {agencyType}
              <div class="d--flex"></div>
            </div>
            <div className="d--flex gap--xl">
              <div className="d--flex flex--column gap--md w--full">
                <div className="w--full d--flex gap--xl">
                  <div className="d--flex flex--column gap--sm  w--full">
                    <div className="font--sm font--600"> Name</div>
                    <div className="font--sm ">{agencyName}</div>
                  </div>
                </div>
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600"> Email</div>
                  <div className="font--sm ">{agencyEmail}</div>
                </div>
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600"> Address 1</div>
                  <div className="font--sm ">{address_one}</div>
                </div>
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600"> City</div>
                  <div className="font--sm ">{city}</div>
                </div>
              </div>
              <div className="d--flex flex--column gap--md w--full">
                <div className="w--full d--flex gap--xl">
                  <div className="d--flex flex--column gap--sm  w--full">
                    <div className="font--sm font--600"> Phone</div>
                    <div className="font--sm ">{agencyPhone}</div>
                  </div>
                </div>
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600">NPN</div>
                  <div className="font--sm ">{agencyNPN}</div>
                </div>
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600"> Address 2</div>
                  <div className="font--sm ">{address_two || '---'}</div>
                </div>
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600"> ZipCode</div>
                  <div className="font--sm ">{zipCode || '---'}</div>
                </div>
              </div>
              <div className="d--flex flex--column gap--md w--full">
                <div className="w--full d--flex gap--xl">
                  <div className="d--flex flex--column gap--sm  w--full">
                    <div className="font--sm font--600">EIN</div>
                    <div className="font--sm ">{agencyEIN}</div>
                  </div>
                </div>

                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600">Website</div>
                  <div className="font--sm ">{ownAgencyWebsite || '---'}</div>
                </div>
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600">State</div>
                  <div className="font--sm ">{state || '---'}</div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isAssociatedAgency && (
          <div className="w--full d--flex flex--column gap--md">
            <div className="label--control font--md font--500 m-b--xs  h-min--44  d--flex gap--sm align-items--center text--black bg--black-50 border-full--black-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
              Associated Agency Info
              <div class="d--flex"></div>
            </div>
            <div className="d--flex gap--xl">
              <div className="d--flex flex--column gap--md w--full">
                <div className="w--full d--flex gap--xl">
                  <div className="d--flex flex--column gap--sm  w--full">
                    <div className="font--sm font--600"> Name</div>
                    <div className="font--sm ">{asagencyName}</div>
                  </div>
                </div>

                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600">NPN</div>
                  <div className="font--sm ">{asagencyNPN}</div>
                </div>

                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600">State</div>
                  <div className="font--sm ">{asstate || '---'}</div>
                </div>
              </div>
              <div className="d--flex flex--column gap--md w--full">
                <div className="w--full d--flex gap--xl">
                  <div className="d--flex flex--column gap--sm  w--full">
                    <div className="font--sm font--600"> Phone</div>
                    <div className="font--sm ">{asagencyPhone}</div>
                  </div>
                </div>

                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600"> Address 1</div>
                  <div className="font--sm ">{asaddress_one}</div>
                </div>
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600"> City</div>
                  <div className="font--sm ">{ascity}</div>
                </div>
              </div>
              <div className="d--flex flex--column gap--md w--full">
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600"> Email</div>
                  <div className="font--sm ">{asagencyEmail}</div>
                </div>
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600"> Address 2</div>
                  <div className="font--sm ">{asaddress_two || '---'}</div>
                </div>
                <div className="d--flex flex--column gap--sm  w--full">
                  <div className="font--sm font--600"> ZipCode</div>
                  <div className="font--sm ">{aszipCode || '---'}</div>
                </div>
              </div>
            </div>
          </div>
        )}

        {planInfo ? <PlanDetails planInfo={planInfo} /> : null}
      </div>
    </div>
  );
};

export default AgencyProfile;

import React from 'react';
import PieChart from './Charts/PieChart';
import PieChart2 from './Charts/PieChart2';

const AgentDashboard = () => {
  return (
    <div className="p--md ">
      <div>
        <PieChart />
      </div>
      <div>
        <PieChart2 />
      </div>
    </div>
  );
};

export default AgentDashboard;

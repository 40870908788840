import React, { useState } from 'react';
import useIcons from '../../assets/icons/useIcons';

const PasswordInput = (
  {
    placeholder,
    label,
    error,
    extraClasses = '',
    paddingLeft = 'md',
    paddingRight = 'md',
    height = '40',
    border = true,
    radiusLeft = 'sm',
    radiusRight = 'sm',
    required = true,
    isTranslate = true,
    ...rest
  },
  ref,
) => {
  const text = error || label || '';

    const { EyeOffIcon, EyeIcon } = useIcons();
  
  const [showPassword, setShowPassword] = useState(false);
  return (


    <div className="position-relative w--full">
             {text && (
        <label
          className={`label--control font--sm font--500 m-b--sm d--flex   ${
            error ? 'text--danger' : 'text--grey'
          }`}
        >
          {text}
          {required && (
            <span className="m-l--xs font--sm text--danger ">*</span>
          )}
        </label>
      )}
                <div className="w--full position--relative">
                <input
        {...rest}
        ref={ref}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        className={`form--control w--full h-min--${height}  r-t-left--${radiusLeft} r-t-right--${radiusRight} r-b-left--${radiusLeft} r-b-right--${radiusRight} p-l--${paddingLeft} p-r--${paddingRight} ${extraClasses} ${
          error
            ? 'border-full--danger'
            : border
            ? 'border-full--black-200'
            : 'border-full--transparent'
        }`}
      />

                  <div
                    className="pointer svg--sm position--absolute bottom--7 right--10 translate-middle-y "
                    onClick={() => setShowPassword((_) => !_)}

                  >
                    {showPassword ? (
                      <EyeIcon width={20} height={20} />
                    ) : (
                      <EyeOffIcon width={20} height={20} />
                    )}
                  </div>
                </div>
              </div>



  );
};

export default React.forwardRef(PasswordInput);

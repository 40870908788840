import { useState } from 'react';
import useIcons from '../../../../../assets/icons/useIcons';
import useDashBoardLogin from '../../../../../hooks/useDashBoardLogin';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../../../../helpers/functions';
import Dropdown from '../../../../../components/Dropdown';
import TableManager from '../../../../../components/TableManager';
import {
  agentClaimList,
  agentConsumerList,
  agentHRAList,
  getConsumer,
  getConsumerHRAList,
} from '../../../../../api';

const filterInitialValues = {
  filter: [
    {
      key: 'account_number',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter account number',
      inputType: 'number',
      inputLabel: 'Account number',
    },
    {
      key: 'email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter Email',
      inputType: 'email',
      inputLabel: 'Email',
    },
  ],
};

const initialEditInstance = { isEdit: false, editData: {} };
export default function ConsumerHRA() {
  const { id } = useParams();

  const { SuccessOutlineIcon, CrossIcon } = useIcons();

  const [addMember, setAddMember] = useState(false);

  const { mutate: mutateDashBoardLogin } = useDashBoardLogin();

  const [editUserInstance, setEditUserInstance] = useState(initialEditInstance);
  const { MoreVIcon } = useIcons();
  const navigate = useNavigate();

  const handleDropListClick = async ({ value }, data) => {
    const { _id } = data || {};

    switch (value) {
      case 'edit':
        // setEditUserInstance({ isEdit: true, editData: data });
        // setAddMember(true);
        break;
      case 'change-status':
        // handleChangeStaus(data);
        break;
      case 'view':
        // navigate(`/user-info/${data?.uuid}/profile`);
        break;
      case 'go_to_dashboard':
        mutateDashBoardLogin(_id);

        break;

      default:
        break;
    }
  };

  const handleUserDetailsModal = (data) => {
    // navigate(`/user-info/${data?.uuid}/dashboard`);
    navigate(`/user-info/${data?.uuid}/profile`);
  };

  const columns = [
    {
      accessorKey: 'firstName',
      header: () => 'Name',
      cell: (props) => `${props.getValue()} ${props.row.original?.lastName}`,
      meta: {
        width: '30%',
      },
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
      meta: {
        width: '25%',
      },
    },
    {
      accessorKey: 'adultFamilyMembers',
      header: () => 'Members',
      cell: (props) => {
        return (
          <div
            // onClick={() => {
            //   if (props.getValue()?.length > 0) {
            //     setOpenAsideModal(true);
            //     setRowData(props?.row?.original);
            //   }
            // }}
            className="c--pointer bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
          >
            {props.getValue()?.length}
          </div>
        );
      },

      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
    // {
    //   accessorKey: 'minorFamilyMembers',
    //   header: () => 'Minor Members',
    //   cell: (props) => {
    //     return (
    //       <div
    //         onClick={() => {
    //           if (props.getValue()?.length > 0) {
    //             setOpenAsideModal(true);
    //             setRowData(props?.row?.original);
    //           }
    //         }}
    //         className="c--pointer bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
    //       >
    //         {props.getValue()?.length}
    //       </div>
    //     );
    //   },

    //   meta: {
    //     textAlign: 'center',
    //     width: '8%',
    //   },
    // },
    {
      accessorKey: 'hraDocumentSignStatus',
      header: () => 'HRA Status',
      cell: (props) => {
        const val = props.getValue();
        return (
          <span
            className={`${
              val === 'PENDING' ? 'text--warning' : 'text--success'
            }`}
          >
            {val?.at(0) + val?.slice(1)?.toLowerCase()}
          </span>
        );
      },

      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
    {
      accessorKey: 'enrollmentYear',
      header: () => 'Enrollment',

      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
  ];

  return (
    <div className="w--full">
      <TableManager
        {...{
          fetcherKey: 'getConsumerHRAList',
          fetcherFn: getConsumerHRAList,
          columns,
          name: 'HRA Program',
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          selectCBForTableData: (tblData) => tblData?.data?.data?.data,
          extraParams: { uniqueCodeId: id },
          fullTableClass: 'table--responsive--search-scroll--full',
        }}
      />
    </div>
  );
}

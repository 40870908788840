const fallbackWidth = '24';
const fallbackHeight = '24';
export default function useIcons() {
  const PDFIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 384 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"></path>
      </svg>
    );
  };

  const MissedCall = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0599 5.99992L20.2999 4.75992C20.5899 4.46992 20.5899 3.98992 20.2999 3.69992C20.0099 3.40992 19.5299 3.40992 19.2399 3.69992L17.9999 4.93992L16.7599 3.69992C16.4699 3.40992 15.9899 3.40992 15.6999 3.69992C15.4099 3.98992 15.4099 4.46992 15.6999 4.75992L16.9399 5.99992L15.6999 7.23992C15.4099 7.52992 15.4099 8.00992 15.6999 8.29992C15.8499 8.44992 16.0399 8.51992 16.2299 8.51992C16.4199 8.51992 16.6099 8.44992 16.7599 8.29992L17.9999 7.05992L19.2399 8.29992C19.3899 8.44992 19.5799 8.51992 19.7699 8.51992C19.9599 8.51992 20.1499 8.44992 20.2999 8.29992C20.5899 8.00992 20.5899 7.52992 20.2999 7.23992L19.0599 5.99992Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M11.79 14.21L8.52 17.48C8.16 17.16 7.81 16.83 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.09 13.57 11.44 13.91 11.79 14.21Z"
          fill="currentColor"
        />
        <path
          d="M21.9701 18.33C21.9701 18.61 21.9201 18.9 21.8201 19.18C21.7901 19.26 21.7601 19.34 21.7201 19.42C21.5501 19.78 21.3301 20.12 21.0401 20.44C20.5501 20.98 20.0101 21.37 19.4001 21.62C19.3901 21.62 19.3801 21.63 19.3701 21.63C18.7801 21.87 18.1401 22 17.4501 22C16.4301 22 15.3401 21.76 14.1901 21.27C13.0401 20.78 11.8901 20.12 10.7501 19.29C10.3601 19 9.9701 18.71 9.6001 18.4L12.8701 15.13C13.1501 15.34 13.4001 15.5 13.6101 15.61C13.6601 15.63 13.7201 15.66 13.7901 15.69C13.8701 15.72 13.9501 15.73 14.0401 15.73C14.2101 15.73 14.3401 15.67 14.4501 15.56L15.2101 14.81C15.4601 14.56 15.7001 14.37 15.9301 14.25C16.1601 14.11 16.3901 14.04 16.6401 14.04C16.8301 14.04 17.0301 14.08 17.2501 14.17C17.4701 14.26 17.7001 14.39 17.9501 14.56L21.2601 16.91C21.5201 17.09 21.7001 17.3 21.8101 17.55C21.9101 17.8 21.9701 18.05 21.9701 18.33Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const IncomingCall = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 8.55H16.2C15.79 8.55 15.45 8.21 15.45 7.8V3C15.45 2.59 15.79 2.25 16.2 2.25C16.61 2.25 16.95 2.59 16.95 3V7.05H21C21.41 7.05 21.75 7.39 21.75 7.8C21.75 8.21 21.41 8.55 21 8.55Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M11.79 14.21L8.52 17.48C8.16 17.16 7.81 16.83 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.09 13.57 11.44 13.91 11.79 14.21Z"
          fill="currentColor"
        />
        <path
          d="M21.9701 18.33C21.9701 18.61 21.9201 18.9 21.8201 19.18C21.7901 19.26 21.7601 19.34 21.7201 19.42C21.5501 19.78 21.3301 20.12 21.0401 20.44C20.5501 20.98 20.0101 21.37 19.4001 21.62C19.3901 21.62 19.3801 21.63 19.3701 21.63C18.7801 21.87 18.1401 22 17.4501 22C16.4301 22 15.3401 21.76 14.1901 21.27C13.0401 20.78 11.8901 20.12 10.7501 19.29C10.3601 19 9.9701 18.71 9.6001 18.4L12.8701 15.13C13.1501 15.34 13.4001 15.5 13.6101 15.61C13.6601 15.63 13.7201 15.66 13.7901 15.69C13.8701 15.72 13.9501 15.73 14.0401 15.73C14.2101 15.73 14.3401 15.67 14.4501 15.56L15.2101 14.81C15.4601 14.56 15.7001 14.37 15.9301 14.25C16.1601 14.11 16.3901 14.04 16.6401 14.04C16.8301 14.04 17.0301 14.08 17.2501 14.17C17.4701 14.26 17.7001 14.39 17.9501 14.56L21.2601 16.91C21.5201 17.09 21.7001 17.3 21.8101 17.55C21.9101 17.8 21.9701 18.05 21.9701 18.33Z"
          fill="currentColor"
        />
      </svg>
    );
  };

  const OutgoingCall = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 9.55C19.59 9.55 19.25 9.21 19.25 8.8V4.75H15.2C14.79 4.75 14.45 4.41 14.45 4C14.45 3.59 14.79 3.25 15.2 3.25H20C20.41 3.25 20.75 3.59 20.75 4V8.8C20.75 9.21 20.41 9.55 20 9.55Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M11.79 14.21L8.52 17.48C8.16 17.16 7.81 16.83 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.09 13.57 11.44 13.91 11.79 14.21Z"
          fill="currentColor"
        />
        <path
          d="M21.9701 18.33C21.9701 18.61 21.9201 18.9 21.8201 19.18C21.7901 19.26 21.7601 19.34 21.7201 19.42C21.5501 19.78 21.3301 20.12 21.0401 20.44C20.5501 20.98 20.0101 21.37 19.4001 21.62C19.3901 21.62 19.3801 21.63 19.3701 21.63C18.7801 21.87 18.1401 22 17.4501 22C16.4301 22 15.3401 21.76 14.1901 21.27C13.0401 20.78 11.8901 20.12 10.7501 19.29C10.3601 19 9.9701 18.71 9.6001 18.4L12.8701 15.13C13.1501 15.34 13.4001 15.5 13.6101 15.61C13.6601 15.63 13.7201 15.66 13.7901 15.69C13.8701 15.72 13.9501 15.73 14.0401 15.73C14.2101 15.73 14.3401 15.67 14.4501 15.56L15.2101 14.81C15.4601 14.56 15.7001 14.37 15.9301 14.25C16.1601 14.11 16.3901 14.04 16.6401 14.04C16.8301 14.04 17.0301 14.08 17.2501 14.17C17.4701 14.26 17.7001 14.39 17.9501 14.56L21.2601 16.91C21.5201 17.09 21.7001 17.3 21.8101 17.55C21.9101 17.8 21.9701 18.05 21.9701 18.33Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const CheckIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4605 8.40802C16.7874 8.66232 16.8463 9.13353 16.592 9.46049L12.585 14.6123C11.9613 15.4143 10.7881 15.5183 10.033 14.8387L7.49828 12.5575C7.1904 12.2804 7.16544 11.8062 7.44254 11.4983C7.71963 11.1904 8.19385 11.1655 8.50173 11.4426L11.0364 13.7238C11.1443 13.8209 11.3119 13.806 11.401 13.6914L15.408 8.53958C15.6623 8.21262 16.1335 8.15372 16.4605 8.40802Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const UserIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  const DashboardIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M2 12H12V22H7.81C4.17 22 2 19.83 2 16.19V12Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M22 7.81V12H12V2H16.19C19.83 2 22 4.17 22 7.81Z"
          fill="currentColor"
        />
        <path
          d="M12 2V12H2V7.81C2 4.17 4.17 2 7.81 2H12Z"
          fill="currentColor"
        />
        <path
          d="M22 12V16.19C22 19.83 19.83 22 16.19 22H12V12H22Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const FilterIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M18.8188 2H5.18117C3.442 2 2.40466 3.91555 3.36937 5.34564L9.09107 12.8274C9.56799 13.5344 9.82249 14.3651 9.82249 15.2148V20.9219C9.82249 21.8805 10.9952 22.3605 11.6811 21.6827L13.8586 19.5307C14.0628 19.329 14.1775 19.0553 14.1775 18.7699V15.2148C14.1775 14.3651 14.432 13.5344 14.9089 12.8274L20.6306 5.34564C21.5953 3.91555 20.558 2 18.8188 2Z"
          fill="currentColor"
        />
        <path
          d="M18.8188 2H5.18117C3.442 2 2.40466 3.91555 3.36937 5.34564L9.09107 12.8274C9.33282 13.1858 9.51742 13.576 9.64069 13.9843L14.3542 14.0012C14.4775 13.5868 14.6638 13.1908 14.9089 12.8274L20.6306 5.34564C21.5953 3.91555 20.558 2 18.8188 2Z"
          fill="currentColor"
        />
      </svg>
    );
  };

  const GroupIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 25 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M9.00146 2C6.38146 2 4.25146 4.13 4.25146 6.75C4.25146 9.32 6.26147 11.4 8.88146 11.49C8.96146 11.48 9.04147 11.48 9.10147 11.49C9.12147 11.49 9.13146 11.49 9.15146 11.49C9.16146 11.49 9.16146 11.49 9.17146 11.49C11.7315 11.4 13.7415 9.32 13.7515 6.75C13.7515 4.13 11.6215 2 9.00146 2Z"
          fill="currentColor"
        />
        <path
          d="M14.0815 14.15C11.2915 12.29 6.74149 12.29 3.93149 14.15C2.66149 15 1.96149 16.15 1.96149 17.38C1.96149 18.61 2.66149 19.75 3.92149 20.59C5.32149 21.53 7.16149 22 9.00149 22C10.8415 22 12.6815 21.53 14.0815 20.59C15.3415 19.74 16.0415 18.6 16.0415 17.36C16.0315 16.13 15.3415 14.99 14.0815 14.15Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M19.9915 7.33998C20.1515 9.27998 18.7715 10.98 16.8615 11.21C16.8515 11.21 16.8515 11.21 16.8415 11.21H16.8115C16.7515 11.21 16.6914 11.21 16.6414 11.23C15.6714 11.28 14.7815 10.97 14.1115 10.4C15.1415 9.47998 15.7315 8.09998 15.6115 6.59998C15.5415 5.78998 15.2615 5.04998 14.8415 4.41998C15.2215 4.22998 15.6615 4.10998 16.1115 4.06998C18.0715 3.89998 19.8215 5.35998 19.9915 7.33998Z"
          fill="currentColor"
        />
        <path
          d="M21.9915 16.59C21.9115 17.56 21.2915 18.4 20.2515 18.97C19.2515 19.52 17.9915 19.78 16.7415 19.75C17.4615 19.1 17.8815 18.29 17.9615 17.43C18.0615 16.19 17.4715 15 16.2915 14.05C15.6215 13.52 14.8415 13.1 13.9915 12.79C16.2015 12.15 18.9815 12.58 20.6915 13.96C21.6115 14.7 22.0815 15.63 21.9915 16.59Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const ListIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 3.5H5.49999C5.18999 3.5 4.88999 3.65 4.69999 3.9L3.19999 5.9C2.70999 6.56 3.17999 7.5 3.99999 7.5H18.5C18.81 7.5 19.11 7.35 19.3 7.1L20.8 5.1C21.29 4.44 20.82 3.5 20 3.5Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M3.99999 10H18.5C18.81 10 19.11 10.15 19.3 10.4L20.8 12.4C21.29 13.06 20.82 14 20 14H5.49999C5.18999 14 4.88999 13.85 4.69999 13.6L3.19999 11.6C2.70999 10.94 3.17999 10 3.99999 10Z"
          fill="currentColor"
        />
        <path
          d="M20 16.5H5.49999C5.18999 16.5 4.88999 16.65 4.69999 16.9L3.19999 18.9C2.70999 19.56 3.17999 20.5 3.99999 20.5H18.5C18.81 20.5 19.11 20.35 19.3 20.1L20.8 18.1C21.29 17.44 20.82 16.5 20 16.5Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const CallIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M11.79 14.21L8.52 17.48C8.16 17.16 7.81 16.83 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.09 13.57 11.44 13.91 11.79 14.21Z"
          fill="currentColor"
        />
        <path
          d="M21.9701 18.33C21.9701 18.61 21.9201 18.9 21.8201 19.18C21.7901 19.26 21.7601 19.34 21.7201 19.42C21.5501 19.78 21.3301 20.12 21.0401 20.44C20.5501 20.98 20.0101 21.37 19.4001 21.62C19.3901 21.62 19.3801 21.63 19.3701 21.63C18.7801 21.87 18.1401 22 17.4501 22C16.4301 22 15.3401 21.76 14.1901 21.27C13.0401 20.78 11.8901 20.12 10.7501 19.29C10.3601 19 9.9701 18.71 9.6001 18.4L12.8701 15.13C13.1501 15.34 13.4001 15.5 13.6101 15.61C13.6601 15.63 13.7201 15.66 13.7901 15.69C13.8701 15.72 13.9501 15.73 14.0401 15.73C14.2101 15.73 14.3401 15.67 14.4501 15.56L15.2101 14.81C15.4601 14.56 15.7001 14.37 15.9301 14.25C16.1601 14.11 16.3901 14.04 16.6401 14.04C16.8301 14.04 17.0301 14.08 17.2501 14.17C17.4701 14.26 17.7001 14.39 17.9501 14.56L21.2601 16.91C21.5201 17.09 21.7001 17.3 21.8101 17.55C21.9101 17.8 21.9701 18.05 21.9701 18.33Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const CallRecordIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.4" cx="12" cy="12" r="10" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7.75C9.65279 7.75 7.75 9.65279 7.75 12C7.75 14.3472 9.65279 16.25 12 16.25C14.3472 16.25 16.25 14.3472 16.25 12C16.25 9.65279 14.3472 7.75 12 7.75ZM6.25 12C6.25 8.82436 8.82436 6.25 12 6.25C15.1756 6.25 17.75 8.82436 17.75 12C17.75 15.1756 15.1756 17.75 12 17.75C8.82436 17.75 6.25 15.1756 6.25 12Z"
          fill="currentColor"
        />
        <circle cx="12" cy="12" r="1" fill="currentColor" />
      </svg>
    );
  };
  const VoicemailIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 15.5C15 19.09 12.09 22 8.5 22C4.91 22 2 19.09 2 15.5C2 11.91 4.91 9 8.5 9C8.67 9 8.84999 9.01 9.01999 9.02C12.19 9.27 14.73 11.81 14.98 14.98C14.99 15.15 15 15.33 15 15.5Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M22 8.5C22 12.09 19.09 15 15.5 15C15.33 15 15.15 14.99 14.98 14.98C14.73 11.81 12.19 9.27 9.01999 9.02C9.00999 8.85 9 8.67 9 8.5C9 4.91 11.91 2 15.5 2C19.09 2 22 4.91 22 8.5Z"
          fill="currentColor"
        />
        <path
          d="M5.59 2H3C2.45 2 2 2.45 2 3V5.59C2 6.48 3.07999 6.93 3.70999 6.3L6.29999 3.71001C6.91999 3.08001 6.48 2 5.59 2Z"
          fill="currentColor"
        />
        <path
          d="M18.41 22H21C21.55 22 22 21.55 22 21V18.41C22 17.52 20.92 17.07 20.29 17.7L17.7 20.29C17.08 20.92 17.52 22 18.41 22Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const CallHistoryIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7.27148C12.4142 7.27148 12.75 7.60727 12.75 8.02148V12.4809L15.2372 13.31C15.6301 13.441 15.8425 13.8657 15.7115 14.2587C15.5805 14.6516 15.1558 14.864 14.7628 14.733L11.7628 13.733C11.4566 13.6309 11.25 13.3443 11.25 13.0215V8.02148C11.25 7.60727 11.5858 7.27148 12 7.27148Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const ProfileIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.00146 4.96997C3.00146 3.32997 4.34146 2 6.00146 2H18.0015C19.6615 2 21.0015 3.32997 21.0015 4.96997V15.88C21.0015 17.52 19.6615 18.85 18.0015 18.85H17.2415C16.4415 18.85 15.6815 19.16 15.1215 19.72L13.4114 21.41C12.6314 22.18 11.3615 22.18 10.5815 21.41L8.87146 19.72C8.31146 19.16 7.54146 18.85 6.75146 18.85H6.00146C4.34146 18.85 3.00146 17.52 3.00146 15.88V9.03003"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0014 9.99982C13.2882 9.99982 14.3314 8.95662 14.3314 7.6698C14.3314 6.38298 13.2882 5.33984 12.0014 5.33984C10.7146 5.33984 9.67139 6.38298 9.67139 7.6698C9.67139 8.95662 10.7146 9.99982 12.0014 9.99982Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.0015 15.6599C16.0015 13.8599 14.2115 12.3999 12.0015 12.3999C9.79146 12.3999 8.00146 13.8599 8.00146 15.6599"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  const LogoutIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.75 6C8.75 4.20507 10.2051 2.75 12 2.75C13.7949 2.75 15.25 4.20507 15.25 6V8H16C16.2563 8 16.5071 8.02411 16.75 8.0702V6C16.75 3.37665 14.6234 1.25 12 1.25C9.37665 1.25 7.25 3.37665 7.25 6V8.0702C7.49294 8.02411 7.74365 8 8 8H8.75V6Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M4 12C4 9.79086 5.79086 8 8 8H16C18.2091 8 20 9.79086 20 12V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V12Z"
          fill="currentColor"
        />
        <circle cx="12" cy="15" r="2" fill="currentColor" />
      </svg>
    );
  };
  const MoreVIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 3 13"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 1.25C0 0.559644 0.559644 0 1.25 0C1.94036 0 2.5 0.559644 2.5 1.25C2.5 1.94036 1.94036 2.5 1.25 2.5C0.559644 2.5 0 1.94036 0 1.25ZM0 6.25C0 5.55964 0.559644 5 1.25 5C1.94036 5 2.5 5.55964 2.5 6.25C2.5 6.94036 1.94036 7.5 1.25 7.5C0.559644 7.5 0 6.94036 0 6.25ZM1.25 10C0.559644 10 0 10.5596 0 11.25C0 11.9404 0.559644 12.5 1.25 12.5C1.94036 12.5 2.5 11.9404 2.5 11.25C2.5 10.5596 1.94036 10 1.25 10Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const CrossRoundIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2984 15.3588C14.5913 15.6517 15.0662 15.6517 15.3591 15.3588C15.652 15.0659 15.652 14.591 15.3591 14.2982L13.061 12.0001L15.3591 9.70209C15.652 9.4092 15.652 8.93433 15.3591 8.64143C15.0662 8.34854 14.5913 8.34854 14.2984 8.64143L12.0004 10.9395L9.70222 8.6413C9.40933 8.34841 8.93445 8.34841 8.64156 8.6413C8.34867 8.93419 8.34867 9.40907 8.64156 9.70196L10.9397 12.0001L8.64154 14.2983C8.34865 14.5912 8.34865 15.0661 8.64154 15.3589C8.93444 15.6518 9.40931 15.6518 9.70221 15.3589L12.0004 13.0608L14.2984 15.3588Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const RefreshIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M14.892 5.0799C14.022 4.8199 13.062 4.6499 12.002 4.6499C7.21203 4.6499 3.33203 8.5299 3.33203 13.3199C3.33203 18.1199 7.21203 21.9999 12.002 21.9999C16.792 21.9999 20.672 18.1199 20.672 13.3299C20.672 11.5499 20.132 9.8899 19.212 8.5099"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.1322 5.32L13.2422 2"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.1317 5.32007L12.7617 7.78007"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const CaretIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M15.4817 13.2299L11.6917 8.17993H6.0817C5.1217 8.17993 4.6417 9.33993 5.3217 10.0199L10.5017 15.1999C11.3317 16.0299 12.6817 16.0299 13.5117 15.1999L15.4817 13.2299Z"
          fill="currentColor"
        />
        <path
          d="M17.9224 8.17993H11.6924L15.4824 13.2299L18.6924 10.0199C19.3624 9.33993 18.8824 8.17993 17.9224 8.17993Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const PencilIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.75 21C10.75 20.5858 11.0858 20.25 11.5 20.25H21C21.4142 20.25 21.75 20.5858 21.75 21C21.75 21.4142 21.4142 21.75 21 21.75H11.5C11.0858 21.75 10.75 21.4142 10.75 21Z"
          fill="currentColor"
        />
        <path
          d="M7.31963 17.9881L10.7523 17.4977C11.2475 17.427 11.7064 17.1976 12.06 16.8439L18.6883 10.2156C18.6883 10.2156 17.0537 10.2156 15.419 8.58102C13.7844 6.94639 13.7844 5.31177 13.7844 5.31177L7.15616 11.94C6.80248 12.2937 6.57305 12.7526 6.50231 13.2477L6.01193 16.6804C5.90295 17.4433 6.5568 18.0971 7.31963 17.9881Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M20.3227 5.31171L18.6881 3.67708C17.7853 2.77431 16.3216 2.77431 15.4188 3.67708L13.7842 5.31171C13.7842 5.31171 13.7842 6.94634 15.4188 8.58096C17.0534 10.2156 18.6881 10.2156 18.6881 10.2156L20.3227 8.58096C21.2255 7.67818 21.2255 6.21449 20.3227 5.31171Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const BellIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.4617 1.00013C4.84629 0.846296 5.28277 1.03336 5.4366 1.41795C5.59044 1.80253 5.40338 2.23901 5.01879 2.39285L4.85156 2.45974C3.54571 2.98208 2.50405 4.00653 1.96002 5.3035L1.88322 5.48659C1.723 5.86857 1.28347 6.04833 0.901495 5.88811C0.519524 5.72789 0.33976 5.28835 0.499981 4.90638L0.576781 4.72329C1.27624 3.05576 2.61553 1.7386 4.29448 1.06702L4.4617 1.00013ZM13.5382 1.04432C13.1536 0.890485 12.7172 1.07755 12.5633 1.46213C12.4095 1.84672 12.5966 2.2832 12.9811 2.43704L13.1484 2.50393C14.4542 3.02627 15.4959 4.05072 16.0399 5.34769L16.1167 5.53078C16.2769 5.91276 16.7165 6.09252 17.0984 5.9323C17.4804 5.77208 17.6602 5.33254 17.5 4.95057L17.4232 4.76748C16.7237 3.09995 15.3844 1.78279 13.7055 1.11121L13.5382 1.04432ZM14.7772 17.0002C16.6055 17.0002 17.6492 15.1611 16.5522 13.8724C16.0665 13.3018 15.7672 12.6251 15.6867 11.9155L15.2395 7.97543C14.9984 5.85088 13.4123 4.10744 11.2699 3.37391V3.27019C11.2699 2.01653 10.2536 1.00024 8.99997 1.00024C7.74631 1.00024 6.73002 2.01653 6.73002 3.27019V3.37391C4.58762 4.10744 3.00156 5.85088 2.76043 7.97543L2.31324 11.9155C2.2327 12.6251 1.93341 13.3018 1.44775 13.8724C0.350725 15.1611 1.3944 17.0002 3.22278 17.0002H14.7772ZM11.9721 19.0718C11.5147 20.1995 10.3565 21.0002 8.99997 21.0002C7.64345 21.0002 6.48525 20.1995 6.02787 19.0718C6.0088 19.0247 5.99997 18.9742 5.99997 18.9235C5.99997 18.6896 6.18957 18.5 6.42346 18.5H11.5765C11.8104 18.5 12 18.6896 12 18.9235C12 18.9742 11.9911 19.0247 11.9721 19.0718Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const BackAngleIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 25 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M16.192 2H7.81195C4.17195 2 2.00195 4.17 2.00195 7.81V16.18C2.00195 19.83 4.17195 22 7.81195 22H16.182C19.822 22 21.992 19.83 21.992 16.19V7.81C22.002 4.17 19.832 2 16.192 2Z"
          fill="currentColor"
        />
        <path
          d="M13.2619 16.2802C13.0719 16.2802 12.8819 16.2102 12.7319 16.0602L9.20188 12.5302C8.91188 12.2402 8.91188 11.7602 9.20188 11.4702L12.7319 7.94016C13.0219 7.65016 13.5019 7.65016 13.7919 7.94016C14.0819 8.23016 14.0819 8.71016 13.7919 9.00016L10.7919 12.0002L13.7919 15.0002C14.0819 15.2902 14.0819 15.7702 13.7919 16.0602C13.6519 16.2102 13.4619 16.2802 13.2619 16.2802Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const BackRoundIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M12.002 22C17.5248 22 22.002 17.5228 22.002 12C22.002 6.47715 17.5248 2 12.002 2C6.47911 2 2.00195 6.47715 2.00195 12C2.00195 17.5228 6.47911 22 12.002 22Z"
          fill="currentColor"
        />
        <path
          d="M13.2619 16.2799C13.0719 16.2799 12.8819 16.2099 12.7319 16.0599L9.20188 12.5299C8.91188 12.2399 8.91188 11.7599 9.20188 11.4699L12.7319 7.93991C13.0219 7.64991 13.5019 7.64991 13.7919 7.93991C14.0819 8.22991 14.0819 8.70991 13.7919 8.99991L10.7919 11.9999L13.7919 14.9999C14.0819 15.2899 14.0819 15.7699 13.7919 16.0599C13.6519 16.2099 13.4619 16.2799 13.2619 16.2799Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const WarningOutlineIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="17" r="1" fill="currentColor" />
        <path
          d="M11.9999 8.99997V14M4.32789 21H19.6721C21.4445 21 22.5649 19.1433 21.7041 17.6324L14.032 4.16592C13.1464 2.61136 10.8536 2.61136 9.96798 4.16592L2.29587 17.6324C1.4351 19.1433 2.55546 21 4.32789 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const SuccessOutlineIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 3.33782C15.5291 2.48697 13.8214 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 10.7687 21.7775 9.58934 21.3704 8.5M7 10L10.5264 12.8211C11.3537 13.483 12.5536 13.3848 13.2624 12.5973L21 4"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    );
  };
  const InfoOutlineIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="7" r="1" fill="currentColor" />
        <path
          d="M11 10H12V17M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const DoubleArrowLeftIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 17L13 12L18 7M11 17L6 12L11 7"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const DoubleArrowRightIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 17L11 12L6 7M13 17L18 12L13 7"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const ArrowRightIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 18L15 12L9 6"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const ArrowLeftIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 18L9 12L15 6"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const EyeIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M21.25 9.14993C18.94 5.51993 15.56 3.42993 12 3.42993C10.22 3.42993 8.49 3.94993 6.91 4.91993C5.33 5.89993 3.91 7.32993 2.75 9.14993C1.75 10.7199 1.75 13.2699 2.75 14.8399C5.06 18.4799 8.44 20.5599 12 20.5599C13.78 20.5599 15.51 20.0399 17.09 19.0699C18.67 18.0899 20.09 16.6599 21.25 14.8399C22.25 13.2799 22.25 10.7199 21.25 9.14993ZM12 16.0399C9.76 16.0399 7.96 14.2299 7.96 11.9999C7.96 9.76993 9.76 7.95993 12 7.95993C14.24 7.95993 16.04 9.76993 16.04 11.9999C16.04 14.2299 14.24 16.0399 12 16.0399Z"
          fill="currentColor"
        />
        <path
          d="M11.9999 9.13989C10.4299 9.13989 9.1499 10.4199 9.1499 11.9999C9.1499 13.5699 10.4299 14.8499 11.9999 14.8499C13.5699 14.8499 14.8599 13.5699 14.8599 11.9999C14.8599 10.4299 13.5699 9.13989 11.9999 9.13989Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const EyeOffIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M21.2501 9.15004C20.7601 8.37004 20.2001 7.67004 19.6201 7.04004L15.8501 10.81C15.9701 11.18 16.0401 11.58 16.0401 12C16.0401 14.24 14.2301 16.04 12.0001 16.04C11.5801 16.04 11.1801 15.97 10.8101 15.85L7.3501 19.31C8.8101 20.13 10.3901 20.56 12.0001 20.56C13.7801 20.56 15.5101 20.04 17.0901 19.07C18.6701 18.09 20.0901 16.66 21.2501 14.84C22.2501 13.28 22.2501 10.72 21.2501 9.15004Z"
          fill="currentColor"
        />
        <path
          d="M14.0201 9.98014L9.98014 14.0201C9.47014 13.5001 9.14014 12.7801 9.14014 12.0001C9.14014 10.4301 10.4201 9.14014 12.0001 9.14014C12.7801 9.14014 13.5001 9.47014 14.0201 9.98014Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M18.25 5.75018L14.86 9.14018C14.13 8.40018 13.12 7.96018 12 7.96018C9.76 7.96018 7.96 9.77018 7.96 12.0002C7.96 13.1202 8.41 14.1302 9.14 14.8602L5.76 18.2502H5.75C4.64 17.3502 3.62 16.2002 2.75 14.8402C1.75 13.2702 1.75 10.7202 2.75 9.15018C3.91 7.33017 5.33 5.90018 6.91 4.92018C8.49 3.96018 10.22 3.43018 12 3.43018C14.23 3.43018 16.39 4.25018 18.25 5.75018Z"
          fill="currentColor"
        />
        <path
          d="M14.8601 12.0001C14.8601 13.5701 13.5801 14.8601 12.0001 14.8601C11.9401 14.8601 11.8901 14.8601 11.8301 14.8401L14.8401 11.8301C14.8601 11.8901 14.8601 11.9401 14.8601 12.0001Z"
          fill="currentColor"
        />
        <path
          d="M21.7699 2.22988C21.4699 1.92988 20.9799 1.92988 20.6799 2.22988L2.22988 20.6899C1.92988 20.9899 1.92988 21.4799 2.22988 21.7799C2.37988 21.9199 2.56988 21.9999 2.76988 21.9999C2.96988 21.9999 3.15988 21.9199 3.30988 21.7699L21.7699 3.30988C22.0799 3.00988 22.0799 2.52988 21.7699 2.22988Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const RemoveIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M10.28 20.25H17C19.76 20.25 22 18.01 22 15.25V8.75C22 5.99 19.76 3.75 17 3.75H10.28C8.86999 3.75 7.52999 4.34 6.57999 5.39L3.04999 9.27C1.63999 10.82 1.63999 13.18 3.04999 14.73L6.57999 18.61C7.52999 19.66 8.86999 20.25 10.28 20.25Z"
          fill="currentColor"
        />
        <path
          d="M14.59 12L16.53 10.06C16.82 9.76997 16.82 9.28997 16.53 8.99997C16.24 8.70997 15.76 8.70997 15.47 8.99997L13.53 10.94L11.59 8.99997C11.3 8.70997 10.82 8.70997 10.53 8.99997C10.24 9.28997 10.24 9.76997 10.53 10.06L12.47 12L10.53 13.94C10.24 14.23 10.24 14.71 10.53 15C10.68 15.15 10.87 15.22 11.06 15.22C11.25 15.22 11.44 15.15 11.59 15L13.53 13.06L15.47 15C15.62 15.15 15.81 15.22 16 15.22C16.19 15.22 16.38 15.15 16.53 15C16.82 14.71 16.82 14.23 16.53 13.94L14.59 12Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const DesktopIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 16.25C12.4142 16.25 12.75 16.5858 12.75 17V20.5H15C15.4142 20.5 15.75 20.8358 15.75 21.25C15.75 21.6642 15.4142 22 15 22H9C8.58579 22 8.25 21.6642 8.25 21.25C8.25 20.8358 8.58579 20.5 9 20.5H11.25V17C11.25 16.5858 11.5858 16.25 12 16.25Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V15C22 16.6569 20.6569 18 19 18H5C3.34315 18 2 16.6569 2 15V5Z"
          fill="currentColor"
        />
        <path
          d="M5 18H19C20.6569 18 22 16.6569 22 15V14H2V15C2 16.6569 3.34315 18 5 18Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const SearchIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8649 11C16.8649 14.2391 14.2391 16.8649 11 16.8649C7.76092 16.8649 5.13513 14.2391 5.13513 11C5.13513 7.76092 7.76092 5.13513 11 5.13513C14.2391 5.13513 16.8649 7.76092 16.8649 11ZM11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18ZM19.5588 19.5588C18.9706 20.1471 18.0169 20.1471 17.4287 19.5588L16 18.1301C16.8316 17.5541 17.5541 16.8316 18.1301 16L19.5588 17.4287C20.1471 18.0169 20.1471 18.9706 19.5588 19.5588Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const UploadIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z"
          fill="currentColor"
        />
        <path
          d="M15.8001 2.20999C15.3901 1.79999 14.6801 2.07999 14.6801 2.64999V6.13999C14.6801 7.59999 15.9201 8.80999 17.4301 8.80999C18.3801 8.81999 19.7001 8.81999 20.8301 8.81999C21.4001 8.81999 21.7001 8.14999 21.3001 7.74999C19.8601 6.29999 17.2801 3.68999 15.8001 2.20999Z"
          fill="currentColor"
        />
        <path
          d="M11.5299 12.47L9.52994 10.47C9.51994 10.46 9.50994 10.46 9.50994 10.45C9.44994 10.39 9.36994 10.34 9.28994 10.3C9.27994 10.3 9.27994 10.3 9.26994 10.3C9.18994 10.27 9.10994 10.26 9.02994 10.25C8.99994 10.25 8.97994 10.25 8.94994 10.25C8.88994 10.25 8.81994 10.27 8.75994 10.29C8.72994 10.3 8.70994 10.31 8.68994 10.32C8.60994 10.36 8.52994 10.4 8.46994 10.47L6.46994 12.47C6.17994 12.76 6.17994 13.24 6.46994 13.53C6.75994 13.82 7.23994 13.82 7.52994 13.53L8.24994 12.81V17C8.24994 17.41 8.58994 17.75 8.99994 17.75C9.40994 17.75 9.74994 17.41 9.74994 17V12.81L10.4699 13.53C10.6199 13.68 10.8099 13.75 10.9999 13.75C11.1899 13.75 11.3799 13.68 11.5299 13.53C11.8199 13.24 11.8199 12.76 11.5299 12.47Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const TrashIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4">
          <path
            d="M14.75 22.75H8.75C6.54086 22.75 4.75 20.9591 4.75 18.75V5.75H18.75V18.75C18.75 20.9591 16.9591 22.75 14.75 22.75Z"
            fill="currentColor"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.75 10C14.1642 10 14.5 10.3358 14.5 10.75L14.5 16.75C14.5 17.1642 14.1642 17.5 13.75 17.5C13.3358 17.5 13 17.1642 13 16.75L13 10.75C13 10.3358 13.3358 10 13.75 10Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.75 10C10.1642 10 10.5 10.3358 10.5 10.75L10.5 16.75C10.5 17.1642 10.1642 17.5 9.75 17.5C9.33579 17.5 9 17.1642 9 16.75L9 10.75C9 10.3358 9.33579 10 9.75 10Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.53223 3.22457C9.04226 2.45953 9.9009 2 10.8204 2H12.6796C13.5991 2 14.4577 2.45953 14.9678 3.22457L16.1514 5H20.75C21.1642 5 21.5 5.33579 21.5 5.75C21.5 6.16421 21.1642 6.5 20.75 6.5H2.75C2.33579 6.5 2 6.16421 2 5.75C2 5.33579 2.33579 5 2.75 5H7.34861L8.53223 3.22457Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const BuildingIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 21.25H2C1.59 21.25 1.25 21.59 1.25 22C1.25 22.41 1.59 22.75 2 22.75H22C22.41 22.75 22.75 22.41 22.75 22C22.75 21.59 22.41 21.25 22 21.25Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M17 2H7C4 2 3 3.79 3 6V22H21V6C21 3.79 20 2 17 2Z"
          fill="currentColor"
        />
        <path
          d="M10 17.25H7C6.59 17.25 6.25 16.91 6.25 16.5C6.25 16.09 6.59 15.75 7 15.75H10C10.41 15.75 10.75 16.09 10.75 16.5C10.75 16.91 10.41 17.25 10 17.25Z"
          fill="currentColor"
        />
        <path
          d="M17 17.25H14C13.59 17.25 13.25 16.91 13.25 16.5C13.25 16.09 13.59 15.75 14 15.75H17C17.41 15.75 17.75 16.09 17.75 16.5C17.75 16.91 17.41 17.25 17 17.25Z"
          fill="currentColor"
        />
        <path
          d="M10 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H10C10.41 11.25 10.75 11.59 10.75 12C10.75 12.41 10.41 12.75 10 12.75Z"
          fill="currentColor"
        />
        <path
          d="M17 12.75H14C13.59 12.75 13.25 12.41 13.25 12C13.25 11.59 13.59 11.25 14 11.25H17C17.41 11.25 17.75 11.59 17.75 12C17.75 12.41 17.41 12.75 17 12.75Z"
          fill="currentColor"
        />
        <path
          d="M10 8.25H7C6.59 8.25 6.25 7.91 6.25 7.5C6.25 7.09 6.59 6.75 7 6.75H10C10.41 6.75 10.75 7.09 10.75 7.5C10.75 7.91 10.41 8.25 10 8.25Z"
          fill="currentColor"
        />
        <path
          d="M17 8.25H14C13.59 8.25 13.25 7.91 13.25 7.5C13.25 7.09 13.59 6.75 14 6.75H17C17.41 6.75 17.75 7.09 17.75 7.5C17.75 7.91 17.41 8.25 17 8.25Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const HeadPhoneIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M2.75006 18.6498C2.34006 18.6498 2.00006 18.3098 2.00006 17.8998V12.1998C1.95006 9.4898 2.96006 6.9298 4.84006 5.0098C6.72006 3.0998 9.24006 2.0498 11.9501 2.0498C17.4901 2.0498 22.0001 6.5598 22.0001 12.0998V17.7998C22.0001 18.2098 21.6601 18.5498 21.2501 18.5498C20.8401 18.5498 20.5001 18.2098 20.5001 17.7998V12.0998C20.5001 7.3898 16.6701 3.5498 11.9501 3.5498C9.64006 3.5498 7.50006 4.43981 5.91006 6.0598C4.31006 7.68981 3.46006 9.85981 3.50006 12.1798V17.8898C3.50006 18.3098 3.17006 18.6498 2.75006 18.6498Z"
          fill="currentColor"
        />
        <path
          d="M5.94 12.4502H5.81C3.71 12.4502 2 14.1602 2 16.2602V18.1402C2 20.2402 3.71 21.9502 5.81 21.9502H5.94C8.04 21.9502 9.75 20.2402 9.75 18.1402V16.2602C9.75 14.1602 8.04 12.4502 5.94 12.4502Z"
          fill="currentColor"
        />
        <path
          d="M18.19 12.4502H18.06C15.96 12.4502 14.25 14.1602 14.25 16.2602V18.1402C14.25 20.2402 15.96 21.9502 18.06 21.9502H18.19C20.29 21.9502 22 20.2402 22 18.1402V16.2602C22 14.1602 20.29 12.4502 18.19 12.4502Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const DownloadIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z"
          fill="currentColor"
        />
        <path
          d="M15.8001 2.20999C15.3901 1.79999 14.6801 2.07999 14.6801 2.64999V6.13999C14.6801 7.59999 15.9201 8.80999 17.4301 8.80999C18.3801 8.81999 19.7001 8.81999 20.8301 8.81999C21.4001 8.81999 21.7001 8.14999 21.3001 7.74999C19.8601 6.29999 17.2801 3.68999 15.8001 2.20999Z"
          fill="currentColor"
        />
        <path
          d="M12.2799 14.72C11.9899 14.43 11.5099 14.43 11.2199 14.72L10.4999 15.44V11.25C10.4999 10.84 10.1599 10.5 9.74994 10.5C9.33994 10.5 8.99994 10.84 8.99994 11.25V15.44L8.27994 14.72C7.98994 14.43 7.50994 14.43 7.21994 14.72C6.92994 15.01 6.92994 15.49 7.21994 15.78L9.21994 17.78C9.22994 17.79 9.23994 17.79 9.23994 17.8C9.29994 17.86 9.37994 17.91 9.45994 17.95C9.55994 17.98 9.64994 18 9.74994 18C9.84994 18 9.93994 17.98 10.0299 17.94C10.1199 17.9 10.1999 17.85 10.2799 17.78L12.2799 15.78C12.5699 15.49 12.5699 15.01 12.2799 14.72Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const GraphIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 22H2C1.59 22 1.25 21.66 1.25 21.25C1.25 20.84 1.59 20.5 2 20.5H22C22.41 20.5 22.75 20.84 22.75 21.25C22.75 21.66 22.41 22 22 22Z"
          fill="currentColor"
        />
        <path
          d="M9.75 4V22H14.25V4C14.25 2.9 13.8 2 12.45 2H11.55C10.2 2 9.75 2.9 9.75 4Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M3 10V22H7V10C7 8.9 6.6 8 5.4 8H4.6C3.4 8 3 8.9 3 10Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M17 15V22H21V15C21 13.9 20.6 13 19.4 13H18.6C17.4 13 17 13.9 17 15Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const BillingIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
          fill="currentColor"
        />
        <path
          d="M15.5 2V9.85999C15.5 10.3 14.98 10.52 14.66 10.23L12.34 8.09C12.15 7.91 11.85 7.91 11.66 8.09L9.34003 10.23C9.02003 10.53 8.5 10.3 8.5 9.85999V2H15.5Z"
          fill="currentColor"
        />
        <path
          d="M17.5 14.75H13.25C12.84 14.75 12.5 14.41 12.5 14C12.5 13.59 12.84 13.25 13.25 13.25H17.5C17.91 13.25 18.25 13.59 18.25 14C18.25 14.41 17.91 14.75 17.5 14.75Z"
          fill="currentColor"
        />
        <path
          d="M17.5 18.75H9C8.59 18.75 8.25 18.41 8.25 18C8.25 17.59 8.59 17.25 9 17.25H17.5C17.91 17.25 18.25 17.59 18.25 18C18.25 18.41 17.91 18.75 17.5 18.75Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  const PlayIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 11.5228V7.96284C4 3.54284 7.13 1.73284 10.96 3.94284L14.05 5.72284L17.14 7.50284C20.97 9.71284 20.97 13.3328 17.14 15.5428L14.05 17.3228L10.96 19.1028C7.13 21.3128 4 19.5028 4 15.0828V11.5228Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const CrossIcon = ({ width, height }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        fill="currentColor"
        className="bi bi-x-circle"
        viewBox="0 0 16 16"
      >
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
      </svg>
    );
  };
  const BackLongIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 12H4M4 12L10 18M4 12L10 6"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  const ConsumerIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.4314 2.42C11.4014 1.86 12.6014 1.86 13.5814 2.42L19.5214 5.84999C20.4914 6.40999 21.0914 7.45003 21.0914 8.58003V15.42C21.0914 16.54 20.4914 17.58 19.5214 18.15L13.5814 21.58C12.6114 22.14 11.4114 22.14 10.4314 21.58L4.49139 18.15C3.52139 17.59 2.92139 16.55 2.92139 15.42V8.58003C2.92139 7.46003 3.52139 6.41999 4.49139 5.84999L6.39139 4.75002"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0014 10.9998C13.2882 10.9998 14.3314 9.95662 14.3314 8.6698C14.3314 7.38298 13.2882 6.33984 12.0014 6.33984C10.7146 6.33984 9.67139 7.38298 9.67139 8.6698C9.67139 9.95662 10.7146 10.9998 12.0014 10.9998Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.0015 16.6599C16.0015 14.8599 14.2115 13.3999 12.0015 13.3999C9.79146 13.3999 8.00146 14.8599 8.00146 16.6599"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  const ClaimIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 7.09469C3 5.57469 3.14 4.49469 3.5 3.72469C3.5 3.71469 3.50002 3.70469 3.52002 3.69469C4.09002 2.96469 5.09998 3.14469 6.22998 4.35469L6.23999 4.36469C7.05999 5.23469 8.31002 5.16469 9.02002 4.21469L10.04 2.86469C10.44 2.32469 10.97 2.05469 11.5 2.05469C12.03 2.05469 12.56 2.32469 12.96 2.86469L13.97 4.20469C14.69 5.16469 15.95 5.23469 16.77 4.35469C17.46 3.61469 18.1 3.26469 18.63 3.26469C18.97 3.26469 19.26 3.41469 19.48 3.69469C19.5 3.70469 19.5 3.71469 19.5 3.72469C19.86 4.49469 20 5.57469 20 7.09469V17.0147C20 18.5347 19.86 19.6147 19.5 20.3847C19.5 20.3947 19.49 20.4147 19.48 20.4247C19.26 20.7047 18.97 20.8447 18.63 20.8447C18.1 20.8447 17.46 20.4947 16.77 19.7547C15.95 18.8747 14.69 18.9447 13.97 19.9047L12.96 21.2447C12.56 21.7847 12.03 22.0547 11.5 22.0547C10.97 22.0547 10.44 21.7847 10.04 21.2447L9.02002 19.8947C8.31002 18.9447 7.05999 18.8747 6.23999 19.7447L6.22998 19.7547C5.09998 20.9647 4.10002 21.1447 3.52002 20.4247C3.51002 20.4147 3.5 20.3947 3.5 20.3847C3.14 19.6147 3 18.5347 3 17.0147V11.0247"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 10.3047H16"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 10.3047H12"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 13.8047H14"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  const InviteIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 9L22 6M22 6L19 3M22 6L16 6M16 21V19.8C16 18.1198 16 17.2798 15.673 16.638C15.3854 16.0735 14.9265 15.6146 14.362 15.327C13.7202 15 12.8802 15 11.2 15H6.8C5.11984 15 4.27976 15 3.63803 15.327C3.07354 15.6146 2.6146 16.0735 2.32698 16.638C2 17.2798 2 18.1198 2 19.8V21M12.5 7.5C12.5 9.433 10.933 11 9 11C7.067 11 5.5 9.433 5.5 7.5C5.5 5.567 7.067 4 9 4C10.933 4 12.5 5.567 12.5 7.5Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  const ProgramIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V11.02"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 13L8 15L10 17"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 13L16 15L14 17"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  const SendInviteIcon = ({ width, height }) => {
    return (
      <svg
        width={width || fallbackWidth}
        height={height || fallbackHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0215 3.01001C14.1815 2.37001 13.1415 2 12.0015 2C9.24146 2 7.00146 4.24 7.00146 7C7.00146 9.76 9.24146 12 12.0015 12C14.7615 12 17.0015 9.76 17.0015 7"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.41162 22C3.41162 18.13 7.26162 15 12.0016 15C12.9616 15 13.8916 15.13 14.7616 15.37"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.0015 18C22.0015 18.75 21.7915 19.46 21.4215 20.06C21.2115 20.42 20.9415 20.74 20.6315 21C19.9315 21.63 19.0115 22 18.0015 22C16.5415 22 15.2715 21.22 14.5815 20.06C14.2115 19.46 14.0015 18.75 14.0015 18C14.0015 16.74 14.5815 15.61 15.5015 14.88C16.1915 14.33 17.0615 14 18.0015 14C20.2115 14 22.0015 15.79 22.0015 18Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.4414 18L17.4314 18.99L19.5614 17.02"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  return {
    PDFIcon,
    UserIcon,
    GroupIcon,
    DashboardIcon,
    ListIcon,
    CallIcon,
    CallRecordIcon,
    VoicemailIcon,
    CallHistoryIcon,
    ProfileIcon,
    LogoutIcon,
    MoreVIcon,
    CrossRoundIcon,
    RefreshIcon,
    CaretIcon,
    PencilIcon,
    TrashIcon,
    BellIcon,
    BackAngleIcon,
    BackRoundIcon,
    FilterIcon,
    WarningOutlineIcon,
    SuccessOutlineIcon,
    InfoOutlineIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    MissedCall,
    RemoveIcon,
    OutgoingCall,
    IncomingCall,
    DesktopIcon,
    SearchIcon,
    UploadIcon,
    BuildingIcon,
    HeadPhoneIcon,
    GraphIcon,
    BillingIcon,
    PlayIcon,
    EyeOffIcon,
    EyeIcon,
    CheckIcon,
    DownloadIcon,
    CrossIcon,
    BackLongIcon,
    ConsumerIcon,
    ClaimIcon,
    InviteIcon,
    SendInviteIcon,
    ProgramIcon,
  };
}

export const EyeIcon = ({ width, height }) => {
  return (
    <svg
      width={width || fallbackWidth}
      height={height || fallbackHeight}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.42012 8.71318C1.28394 8.49754 1.21584 8.38972 1.17772 8.22342C1.14909 8.0985 1.14909 7.9015 1.17772 7.77658C1.21584 7.61028 1.28394 7.50246 1.42012 7.28682C2.54553 5.50484 5.8954 1 11.0004 1C16.1054 1 19.4553 5.50484 20.5807 7.28682C20.7169 7.50246 20.785 7.61028 20.8231 7.77658C20.8517 7.9015 20.8517 8.0985 20.8231 8.22342C20.785 8.38972 20.7169 8.49754 20.5807 8.71318C19.4553 10.4952 16.1054 15 11.0004 15C5.8954 15 2.54553 10.4952 1.42012 8.71318Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0004 11C12.6573 11 14.0004 9.65685 14.0004 8C14.0004 6.34315 12.6573 5 11.0004 5C9.34355 5 8.0004 6.34315 8.0004 8C8.0004 9.65685 9.34355 11 11.0004 11Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const EyeCloseIcon = ({ width, height }) => {
  return (
    <svg
      width={width || fallbackWidth}
      height={height || fallbackHeight}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.74294 3.09232C10.1494 3.03223 10.5686 3 11.0004 3C16.1054 3 19.4553 7.50484 20.5807 9.28682C20.7169 9.5025 20.785 9.61034 20.8231 9.77667C20.8518 9.90159 20.8517 10.0987 20.8231 10.2236C20.7849 10.3899 20.7164 10.4985 20.5792 10.7156C20.2793 11.1901 19.8222 11.8571 19.2165 12.5805M5.72432 4.71504C3.56225 6.1817 2.09445 8.21938 1.42111 9.28528C1.28428 9.50187 1.21587 9.61016 1.17774 9.77648C1.1491 9.9014 1.14909 10.0984 1.17771 10.2234C1.21583 10.3897 1.28393 10.4975 1.42013 10.7132C2.54554 12.4952 5.89541 17 11.0004 17C13.0588 17 14.8319 16.2676 16.2888 15.2766M2.00042 1L20.0004 19M8.8791 7.87868C8.3362 8.42157 8.00042 9.17157 8.00042 10C8.00042 11.6569 9.34356 13 11.0004 13C11.8288 13 12.5788 12.6642 13.1217 12.1213"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Country } from 'country-state-city';
import { formatDate } from '../../../../../helpers/functions';
import PlanDetail from '../../ForAgency/Profile/PlanDetails';

const ConsumerProfile = () => {
  const userDetail = useOutletContext();
  const {
    userInfo: {
      firstName,
      lastName,
      createdAt,
      phone,
      dob,
      email,
      country,
      state,
      city,
    } = {},
  } = userDetail || {};

  return (
    <div className="d--flex w--full flex--column profilePage">
      <h4 className="font--xl font--600 m-b--md h-max--40 m-t--sm">Profile</h4>
      <div className="d--flex gap--md flex--column profilePageSec bg--white p--md radius--sm h--full overflow--auto box-shadow--xs">
        <div className="w--full d--flex flex--column gap--md">
          <div className="label--control font--md font--500 m-b--xs  h-min--44  d--flex gap--sm align-items--center text--black bg--black-50 border-full--black-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
            Personal Info<div class="d--flex"></div>
          </div>
          <div className="d--flex gap--xl">
            <div className="d--flex flex--column gap--md w--full">
              <div className="d--flex flex--column gap--md w--full">
                <div className="font--sm font--600">Registration Date</div>
                <div className="font--sm ">{formatDate(createdAt)}</div>
              </div>
              <div className="d--flex flex--column gap--md w--full">
                <div className="font--sm font--600">Email</div>
                <div className="font--sm ">{email}</div>
              </div>
              <div className="d--flex flex--column gap--md w--full">
                <div className="font--sm font--600">State</div>
                <div className="font--sm ">{state || '---'}</div>
              </div>
            </div>
            <div className="d--flex flex--column gap--md w--full">
              <div className="w--full d--flex gap--xl">
                <div className="d--flex flex--column gap--md w--full">
                  <div className="font--sm font--600">Full Name</div>
                  <div className="font--sm ">
                    {firstName} {lastName}
                  </div>
                </div>
              </div>
              <div className="d--flex flex--column gap--md w--full">
                <div className="font--sm font--600">Phone</div>
                <div className="font--sm ">{phone}</div>
              </div>
              <div className="d--flex flex--column gap--md w--full">
                <div className="font--sm font--600">City</div>
                <div className="font--sm ">{city || '---'}</div>
              </div>
            </div>
            <div className="d--flex flex--column gap--md w--full">
              <div className="d--flex flex--column gap--md w--full">
                <div className="font--sm font--600">Date of Birth:</div>
                <div className="font--sm ">{formatDate(dob) || '---'}</div>
              </div>
              <div className="d--flex flex--column gap--md w--full">
                <div className="font--sm font--600">Country:</div>
                <div className="font--sm ">
                  {Country.getCountryByCode(country)?.name}
                </div>
              </div>{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerProfile;

export const yearList = [
  {
    value: '2024',
    label: '2024',
  },
  {
    value: '2025',
    label: '2025',
  },
];
export const filterTypeArr = [
    {
      value: 'yearly',
      label: 'Yearly',
    },
    {
      value: 'weekly',
      label: 'Current Week',
    },
    {
      value: 'current_month',
      label: 'Current Month',
    },
    {
      value: 'last_month',
      label: 'Last Month',
    },
  ]

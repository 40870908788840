import * as yup from 'yup';
import { array, object, string } from 'yup';
import {
  name,
  postalCode,
  requiredFieldFunction,
} from '../../helpers/yupValidations';

export const detailsInitialValues = {
  name: '',
  country: '',
  timezone: '',
  lang: 'en',
};
export const validationSchema = yup.object({
  name: yup.string().required('Name  is required'),
  country: yup.string().required('Country  is required'),
  timezone: yup.string().required('Timezone  is required'),
  lang: yup.string().required('Language  is required'),
});
export const languageList = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'fr', label: 'French' },
];
export let regexPassword =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!_]).{8,}$/;

export const settingFieldList = [
  {
    label: 'Change Password',
    initValue: 'password',
    validationSchema: yup.object({
      password: yup.string().required('Password is required'),
    }),
    fieldType: 'password',
  },
  { label: 'Add Fund', initValue: 'add_fund' },
  { label: 'Deduct Fund', initValue: 'deduct_fund' },
  {
    label: 'User Account',
    initValue: 'user_account',
    validationSchema: yup.object({}),
    fieldType: 'select',
  },
  { label: 'Add No of Members', initValue: 'members' },
  { label: 'Add Free Call Credit', initValue: 'call_credit' },
  { label: 'Add No of Participants', initValue: 'participants' },
];

export const diagnosedOptions = [
  {
    label: 'Diabetes',
    value: 'diabetes',
  },
  {
    label: 'Hypertension (High Blood Pressure)',
    value: 'hypertension',
  },
  {
    label: 'Heart Disease',
    value: 'heart_disease',
  },
  {
    label: 'Stroke',
    value: 'stroke',
  },
  {
    label: 'High Cholesterol',
    value: 'high_cholestrol',
  },
  {
    label: 'Cancer',
    value: 'cancer',
  },
  {
    label: 'Chronic Kidney Disease',
    value: 'chronic_kidney_disease',
  },
  {
    label: 'Liver Disease',
    value: 'liver_disease',
  },

  {
    label: 'Asthma or Chronic Obstructive Pulmonary Disease (COPD)',
    value: 'copd',
  },

  {
    label: 'Others (please specify)',
    value: 'others',
  },
];
export const familyDiagnosedOptions = [
  {
    label: 'Heart Disease',
    value: 'heart_disease',
  },
  {
    label: 'Cancer',
    value: 'cancer',
  },
  {
    label: 'Diabetes',
    value: 'diabetes',
  },
  {
    label: 'Hypertension (High Blood Pressure)',
    value: 'hypertension',
  },
  {
    label: 'Mental Health Disorders',
    value: 'mental_health_disorders',
  },
  {
    label: 'Stroke',
    value: 'stroke',
  },

  {
    label: 'Others (please specify)',
    value: 'others',
  },
];
export const addictionUseArr = [
  {
    label: 'Tobacco ',
    value: 'tobacco',
  },
  {
    label: `Alcohol`,
    value: 'alcohol',
  },
  {
    label: 'Recreational Drugs',
    value: 'recreational_drugs',
  },
];
export const yesNoQuestionsArr = [
  {
    label: ' Do you currently take any prescription medications?',
    value: 'prescription_medications',
  },
  {
    label: `    Do you have any allergies (medications, food, environmental,
                etc.)?`,
    value: 'allergies',
  },
  {
    label: ' Have you had any surgeries in the past 5 years?',
    value: 'surgery_in_past_5_years',
  },
  {
    label: 'Do you have any chronic pain (back pain, joint pain, etc.)?',
    value: 'chronic_pain',
  },
];

export const yesOrNo = {
  yes: 'Yes',
  no: 'No',
};

export const sleepCycleOptions = [
  {
    label: 'Less than 5 hours',
    value: 'less_than_5',
  },
  {
    label: '5 to 6 hours',
    value: 'five_to_six',
  },
  {
    label: '7 to 8 hours',
    value: 'seven_to_eight',
  },
  {
    label: 'More than 8 hours',
    value: 'more_than_eight',
  },
];

export const exerciseOptions = [
  {
    label: 'Never',
    value: 'never',
  },
  {
    label: '1 to 2 days per week',
    value: 'one_to_two_day',
  },
  {
    label: '3 to 4 days per week',
    value: 'three_to_four_day',
  },
  {
    label: '5 or more days per week',
    value: 'five_or_more_day',
  },
];

export const stressLevelOptions = [
  {
    label: 'Low',
    value: 'low',
  },
  {
    label: 'Moderate',
    value: 'moderate',
  },
  {
    label: 'High',
    value: 'high',
  },
  {
    label: 'Very High',
    value: 'very_high',
  },
];

export const dietOptions = [
  {
    label: 'Poor',
    value: 'poor',
  },
  {
    label: 'Fair',
    value: 'fair',
  },
  {
    label: 'Good',
    value: 'good',
  },
  {
    label: 'Excellent',
    value: 'excellent',
  },
];

export const pastYearOptions = [
  {
    label: 'Never',
    value: 'never',
  },
  {
    label: 'Within the past year',
    value: 'within_past_year',
  },
  {
    label: '1 to 3 years ago',
    value: 'one_to_three_year_ago',
  },
  {
    label: 'More than 3 years ago',
    value: 'more_than_three_year_ago',
  },
];

export const psaTestOptions = [
  {
    label: 'Never',
    value: 'never',
  },
  {
    label: 'Within the past year',
    value: 'within_past_year',
  },
  {
    label: 'More than a year ago',
    value: 'more_than_a_year_ago',
  },
];

export const vaccinationOptions = [
  {
    label: 'Influenza (flu) vaccine',
    value: 'influe',
  },
  {
    label: 'Pneumococcal vaccine',
    value: 'pneumoococcal',
  },
  {
    label: 'COVID-19 vaccine',
    value: 'covid_19',
  },
  {
    label: 'Tetanus booster',
    value: 'tetnus',
  },
  {
    label: 'Shingles vaccine',
    value: 'shingles',
  },
  {
    label: 'Others (please specify)',
    value: 'others',
  },
];
export const timeSpanOptions = [
  {
    label: 'Never',
    value: 'never',
  },
  {
    label: 'Several days',
    value: 'several_days',
  },
  {
    label: 'More than half the days',
    value: 'more_than_half_days',
  },
  {
    label: 'Nearly every day',
    value: 'nearly_every_day',
  },
];

export const healthPrioritiesOptions = [
  {
    label: 'Weight Management',
    value: 'weight_management',
  },
  {
    label: 'Smoking Cessation',
    value: 'smoking_cessation',
  },
  {
    label: 'Managing chronic conditions',
    value: 'chronic_condition',
  },
  {
    label: 'Reducing Stress',
    value: 'reducing_stress',
  },
];

export const wellnessOptions = [
  {
    label: 'Weight Management',
    value: 'weight_management',
  },
  {
    label: 'Smoking Cessation',
    value: 'smoking_cessation',
  },
  {
    label: 'Stress Management',
    value: 'stress_management',
  },
  {
    label: 'Fitness & Exercise',
    value: 'fitness_exercise',
  },
  {
    label: 'Chronic Disease Management',
    value: 'chronic_disease_management',
  },
  {
    label: 'Nutrition',
    value: 'nutrition',
  },

  {
    label: 'Others (please specify)',
    value: 'others',
  },
];

export const genderArr = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Others', value: 'Others' },
];
export const marStatusArr = [
  { label: 'Single', value: 'Single' },
  { label: 'Married', value: 'Married' },
  { label: 'Divorce', value: 'Divorce' },
];
export const exerciseArr = [
  { label: 'Walking', value: 'walking' },
  { label: 'Running', value: 'running' },
  { label: 'Weight Training', value: 'weight_training' },
  { label: 'Yoga', value: 'yoga' },
];

export const hraSchema = object({
  consumer_info: object({
    year: requiredFieldFunction(' Year'),
    state: requiredFieldFunction(' State'),
    city: requiredFieldFunction(' City'),
    address: requiredFieldFunction('  Address'),
    zipCode: postalCode,
    // zipCode: requiredFieldFunction('ZipCode'),
  }),
  member: array().of(
    object({
      relation: requiredFieldFunction('relation'),
      data: object({
        personal_demographic_info: object({
          name,
          date_of_birth: requiredFieldFunction('DOB'),
          gender: requiredFieldFunction('Gender'),
          relation: requiredFieldFunction('relation'),
          marital_status: requiredFieldFunction('Maritial Status'),
          phone: requiredFieldFunction('Phone'),
          email: requiredFieldFunction('Email'),
          // occupation: requiredFieldFunction('Occupation'),
          // primary_care_provider: requiredFieldFunction('Provider'),
          carrierId: requiredFieldFunction('Carrier'),
        }),
        diagnosed_with_conditions_otherValue: string().when(
          'diagnosed_with_conditions',
          {
            is: (val) => {
              return val?.includes('others');
            },
            then: () => string().required('This field is required'),
            otherwise: () => string(),
          },
        ),
        family_diagnosed_with_conditions_other: string().when(
          'family_diagnosed_with_conditions',
          {
            is: (val) => {
              return val?.includes('others');
            },
            then: () => string().required('This field is required'),
            otherwise: () => string(),
          },
        ),
        typeofExValue: string().when('typeofEx', {
          is: (val) => val,
          then: () => string().required('This field is required'),
          otherwise: () => string(),
        }),
        vaccinations_received_in_past_other: string().when(
          'vaccinations_received_in_past',
          {
            is: (val) => {
              return val?.includes('others');
            },
            then: () => string().required('This field is required'),
            otherwise: () => string(),
          },
        ),

        interests_in_wellness_programs_other: string().when(
          'interests_in_wellness_programs',
          {
            is: (val) => {
              return val?.includes('others');
            },
            then: () => string().required('This field is required'),
            otherwise: () => string(),
          },
        ),
      }),
    }),
  ),
});

export const defaultValues = {
  member: [],

  consumer_info: {
    year: '2024',
    state: '',
    city: '',
    zipCode: '',
    address: '',
  },
};

export const relationArr = [
  {
    label: 'Self',
    value: 'Self',
  },
  {
    label: 'Brother',
    value: 'Brother',
  },
  {
    label: 'Sister',
    value: 'Sister',
  },
  {
    label: 'Mother',
    value: 'Mother',
  },
  {
    label: 'Father',
    value: 'Father',
  },
  {
    label: 'Son',
    value: 'Son',
  },
  {
    label: 'Daughter',
    value: 'Daughter',
  },
  {
    label: 'Wife',
    value: 'Wife',
  },
];

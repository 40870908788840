import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Error500Page from '../pages/Error500Page';
import Login from '../pages/Login';
import Agencies from '../pages/Agency';
import Consumer from '../pages/Consumer';
import PageNotFound from '../pages/PageNotFound';
import OuterLayout from '../layouts/OuterLayout';
import UserDetails from '../pages/UserDetails';
import UserInfoLayout from '../layouts/UserInfoLayout';
import Carrier from '../pages/Carrier';
import Documents from '../pages/Documents';
import Claim from '../pages/Claim';
import Agent from '../pages/Agent';
import AgentConsumer from '../pages/UserDetails/Contents/ForAgent/Consumer';
import AgentClaim from '../pages/UserDetails/Contents/ForAgent/Claims';
import AgentInvite from '../pages/UserDetails/Contents/ForAgent/Invite';
import AgentHRA from '../pages/UserDetails/Contents/ForAgent/HRA';
import ConsumerAgent from '../pages/UserDetails/Contents/ForConsumer/Agent';
import AgentProfile from '../pages/UserDetails/Contents/ForAgent/Profile';
import ConsumerHRA from '../pages/UserDetails/Contents/ForConsumer/HRA';
import AgencyProfile from '../pages/UserDetails/Contents/ForAgency/Profile';
import ConsumerProfile from '../pages/UserDetails/Contents/ForConsumer/Profile';
import AgentDashboard from '../pages/UserDetails/Contents/ForAgent/Dashboard';
import Dashboard from '../pages/Dashboard';
import NIPR from '../pages/NIPR';
import BankDetails from '../pages/UserDetails/Contents/ForAgent/BankDetails';

const AuthRemover = lazy(() => import('../auth/AuthRemover'));
const AuthProvider = lazy(() => import('../auth/AuthProvider'));
const AuthLayout = lazy(() => import('../layouts/AuthLayout'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <AuthLayout />
      </AuthProvider>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        path: 'user',
        element: <Agent />,
        id: 'Agent',
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
        id: 'Dashboard',
      },
      {
        path: 'agency',
        element: <Agencies />,
        id: 'Agency',
      },
      {
        path: 'consumer',
        element: <Consumer />,
        id: 'Consumer',
      },
      {
        path: 'carrier',
        element: <Carrier />,
        id: 'Carrier',
      },
      {
        path: 'documents',
        element: <Documents />,
        id: 'Documents',
      },
      {
        path: 'claim',
        element: <Claim />,
        id: 'Claim',
      },
      {
        path: 'NIPR',
        element: <NIPR />,
        id: 'NIPR',
      },
    ],
  },
  {
    path: 'user-info',
    element: (
      <AuthProvider>
        <UserInfoLayout />
      </AuthProvider>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        path: ':id',
        element: <UserDetails />,
        children: [
          //for agent
          {
            index: true,
            path: 'agent-bank',
            element: <BankDetails />,
          },
          {
            index: true,
            path: 'agent-dashboard',
            element: <AgentDashboard />,
          },
          {
            path: 'agent-profile',
            element: <AgentProfile />,
          },
          {
            path: 'consumer',
            element: <AgentConsumer />,
          },
          {
            path: 'claims',
            element: <AgentClaim />,
          },
          {
            path: 'invite',
            element: <AgentInvite />,
          },
          {
            path: 'agent-HRA',
            element: <AgentHRA />,
          },
          //for agency
          {
            path: 'agency-profile',
            element: <AgencyProfile />,
          },
          {
            path: 'send-invite',
            element: <AgentInvite />,
          },

          //for consumer
          {
            path: 'consumer-profile',
            element: <ConsumerProfile />,
          },
          {
            path: 'agent',
            element: <ConsumerAgent />,
          },
          {
            path: 'consumer-HRA',
            element: <ConsumerHRA />,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthRemover>
        <OuterLayout />
      </AuthRemover>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        index: true,
        element: <Login />,
        id: 'Login',
      },
    ],
  },

  {
    path: '*',
    element: <PageNotFound />,
  },
]);

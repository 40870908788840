import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { agentBankInfo } from '../../../../../api';
import Spinner from '../../../../../components/Spinner';

const BankDetails = ({ uniqueCodeId }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['agentBankInfo', uniqueCodeId],
    queryFn: () => agentBankInfo({ uniqueCodeId }),
    select: (data) => data?.data?.data,
    enabled: Boolean(uniqueCodeId),
  });

  const {
    bankInformation: {
      nameOfBank,
      accountName,
      bankAccountNumber,
      bicCode,
    } = {},
  } = data || {};

  if (isLoading) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!data) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        No Bank Account Details Found
      </div>
    );
  }

  return (
    <div className="d--flex gap--lg w--full  p-t--md p-b--md ">
      <div className="w--full bg--white radius--sm d--flex flex--column gap--xl p--lg">
        {/* <div className="font--md font--600 p-b--md border-bottom--black-100">
          Bank Account Details
        </div> */}
        <div className="d--flex w--full">
          <div className="d--flex flex--column w--full gap--md">
            <div className="font--sm font--600">Name of Bank</div>
            <div className="font--sm text--black-600">{nameOfBank}</div>
          </div>
          <div className="d--flex flex--column w--full gap--md">
            <div className="font--sm font--600">Name on the Account</div>
            <div className="font--sm text--black-600">{accountName}</div>
          </div>
        </div>
        <div className="d--flex w--full">
          <div className="d--flex flex--column w--full gap--md">
            <div className="font--sm font--600">
              Bank Account Number
            </div>
            <div className="font--sm text--black-600">{bankAccountNumber}</div>
          </div>
          <div className="d--flex flex--column w--full gap--md">
            <div className="font--sm font--600">Routing Number</div>
            <div className="font--sm text--black-600">{bicCode}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;

export const routes = {
  LOGIN: {
    METHOD: 'POST',
    URL: 'admin/login',
  },
  GET_PROFILE_DETAILS: {
    METHOD: 'POST',
    URL: 'admin/get-profile-detail',
  },
  NIPR_AUTH: {
    METHOD: 'POST',
    URL: 'admin/nipr-authorization',
  },
  NIPR_AUTH_TEST: {
    METHOD: 'POST',
    URL: 'admin/nipr-authorization-test',
  },
  GET_NIPR_AUTH: {
    METHOD: 'GET',
    URL: 'admin/get-nipr-authorization-detail',
  },
  //Agent
  GET_AGENT: {
    METHOD: 'POST',
    URL: 'admin/agent-list',
  },
  DASHBOARD_LOGIN: {
    METHOD: 'POST',
    URL: 'admin/dashboard-login',
  },
  //AGENT VIEW
  AGENT_BANK_INFO: {
    METHOD: 'POST',
    URL: 'admin/get-agent-bInformation',
  },
  AGENT_CONSUMER_LIST: {
    METHOD: 'POST',
    URL: 'admin/agent/consumer-list',
  },
  AGENT_CLAIM_LIST: {
    METHOD: 'POST',
    URL: 'admin/agent/claim-list',
  },
  AGENT_INVITE_LIST: {
    METHOD: 'POST',
    URL: 'admin/agent/invited-list',
  },
  AGENT_HRA_LIST: {
    METHOD: 'POST',
    URL: 'admin/agent/hra-list',
  },

  GET_AGENCY: {
    METHOD: 'POST',
    URL: 'admin/agent-agency-list',
  },
  GET_CONSUMER: {
    METHOD: 'POST',
    URL: 'admin/consumer-list',
  },

  //consumer view
  GET_CONSUMER_AGENT_LIST: {
    METHOD: 'POST',
    URL: 'admin/consumer/agent-list',
  },
  GET_CONSUMER_HRA_LIST: {
    METHOD: 'POST',
    URL: 'admin/consumer/hra-list',
  },
  HRA_DETAILS: {
    METHOD: 'POST',
    URL: 'admin/view-hra',
  },

  GET_CARRIER: {
    METHOD: 'POST',
    URL: 'admin/carrier-list',
  },
  GET_Claims: {
    METHOD: 'POST',
    URL: 'admin/claim-list',
  },
  LOGOUT: {
    METHOD: 'POST',
    URL: 'admin/logout',
  },
  ADD_USER: {
    METHOD: 'POST',
    URL: 'admin/add-tenant',
  },
  ADD_CARRIER: {
    METHOD: 'POST',
    URL: 'admin/add-carrier',
  },
  UPDATE_USER: {
    METHOD: 'POST',
    URL: 'update-user',
  },
  UPDATE_CARRIER: {
    METHOD: 'POST',
    URL: 'admin/update-carrier',
  },
  USER_ACTIVATION: {
    METHOD: 'POST',
    URL: 'activate-user',
  },
  USER_DEACTIVATION: {
    METHOD: 'POST',
    URL: 'deactivate-user',
  },
  ADMIN_USER_DASHBOARD_SATES: {
    METHOD: 'POST',
    URL: 'admin/user/dash-sates',
  },
  ADMIN_CONSENT_DASHBOARD_SATES: {
    METHOD: 'POST',
    URL: 'admin/consent/dash-sates',
  },
  ADMIN_HRA_DASHBOARD_SATES: {
    METHOD: 'POST',
    URL: 'admin/hra-agent-agreement/dash-sates',
  },
  ADMIN_HRA_CONSENT_CARRIER_SATES: {
    METHOD: 'POST',
    URL: 'admin/hra-consent-carrier/dash-sates',
  },
  ADMIN_HRA_CONSENT_STATE_SATES: {
    METHOD: 'POST',
    URL: 'admin/hra-consent-state/dash-sates',
  },
  ADMIN_CONSENT_PIE_SATES: {
    METHOD: 'POST',
    URL: 'admin/consent-pie/dash-sates',
  },
  DID_LISTING: {
    METHOD: 'POST',
    URL: 'get-did-list',
  },
  DID_RELEASE: {
    METHOD: 'POST',
    URL: 'did-release',
  },
  TRANSACTION_LISTING: {
    METHOD: 'POST',
    URL: 'get-transactions',
  },
  PROVIDER_LISTING: {
    METHOD: 'POST',
    URL: 'get-providers',
  },
  ADD_PROVIDER: {
    METHOD: 'POST',
    URL: 'add-provider',
  },
  UPDATE_PROVIDER: {
    METHOD: 'POST',
    URL: 'update-provider',
  },
  DELETE_PROVIDER: {
    METHOD: 'DELETE',
    URL: 'delete-provider',
  },
  RATECARD_LISTING: {
    METHOD: 'POST',
    URL: 'get-rate-cards',
  },
  ADD_RATECARD: {
    METHOD: 'POST',
    URL: 'add-call-rates',
  },
  UPDATE_RATECARD: {
    METHOD: 'POST',
    URL: 'update-rate-card',
  },
  DELETE_RATECARD: {
    METHOD: 'DELETE',
    URL: 'delete-rate-card ',
  },

  CALL_RATES_LISTING: {
    METHOD: 'POST',
    URL: 'get-call-rates',
  },
  // ----------
  GET_AGENT_PROFILE_DETAILS: {
    METHOD: 'POST',
    URL: 'user/user-details',
  },
  GET_AGENT_MEMBERS: {
    METHOD: 'POST',
    URL: 'user/get-members',
  },
  GET_AGENT_AGENTS: {
    METHOD: 'POST',
    URL: 'user/get-agents',
  },
  GET_AGENT_AGENCIES: {
    METHOD: 'POST',
    URL: 'user/get-agencies',
  },
  GET_AGENT_AGENCIES_MEMBERS: {
    METHOD: 'POST',
    URL: 'user/get-agency-members',
  },
  GET_AGENT_CALLS: {
    METHOD: 'POST',
    URL: 'user/get-calls',
  },
  GET_AGENT_BILLING_DETAILS: {
    METHOD: 'POST',
    URL: 'user/billing-details',
  },
  GET_AGENT_PLAN_DETAILS: {
    METHOD: 'POST',
    URL: 'user/plan-details',
  },
  GET_AGENT_DID_NUMBERS: {
    METHOD: 'POST',
    URL: 'user/get-did-numbers',
  },
  GET_AGENT_ALL_DID_DROPDOWN: {
    METHOD: 'POST',
    URL: 'user/get-all-did-list',
  },
  ADD_AGENT: {
    METHOD: 'POST',
    URL: 'user/add-agent',
  },
  EDIT_AGENT: {
    METHOD: 'POST',
    URL: 'user/update-agent',
  },
  DELETE_AGENT: {
    METHOD: 'DELETE',
    URL: 'user/delete-agent',
  },
  // ---------------
  ADD_EMPLOYEE: {
    METHOD: 'POST',
    URL: 'user/add-member',
  },
  EDIT_EMPLOYEE: {
    METHOD: 'POST',
    URL: 'user/update-member',
  },
  DELETE_EMPLOYEE: {
    METHOD: 'DELETE',
    URL: 'user/delete-member',
  },
  // ----------
  GET_PLANS: {
    METHOD: 'POST',
    URL: 'get-plans',
  },
  ADD_PLAN: {
    METHOD: 'POST',
    URL: 'add-plan',
  },
  UPDATE_PLAN: {
    METHOD: 'POST',
    URL: 'update-plan',
  },
  GET_CALLS: {
    METHOD: 'POST',
    URL: 'user/get-calls',
  },
  GET_SMS: {
    METHOD: 'POST',
    URL: 'user/get-sms',
  },
  ACTIVATE_PLAN: {
    METHOD: 'POST',
    URL: 'activate-plan',
  },
  DEACTIVATE_PLAN: {
    METHOD: 'POST',
    URL: 'deactivate-plan',
  },
  PORTING_LIST: {
    METHOD: 'POST',
    URL: 'porting/listing',
  },
  PORTING_STATUS_UPDATE: {
    METHOD: 'POST',
    URL: 'porting',
  },
  USER_LOGIN: {
    METHOD: 'POST',
    URL: 'user-login',
  },
  SUPPORT_LISTING: {
    METHOD: 'POST',
    URL: 'support/listing',
  },
  // Dropdown APIS

  RATE_CARD_DROPDOWN: {
    METHOD: 'POST',
    URL: 'get-all-rate-cards',
  },
  PROVIDER_DROPDOWN: {
    METHOD: 'POST',
    URL: 'get-all-providers',
  },
  PLAN_DROPDOWN: {
    METHOD: 'POST',
    URL: 'get-all-plans',
  },
  GET_ALL_USERS: {
    METHOD: 'POST',
    URL: 'get-all-users',
  },
};

import { useState } from 'react';
import useIcons from '../../../../../assets/icons/useIcons';
import useDashBoardLogin from '../../../../../hooks/useDashBoardLogin';
import { useNavigate, useParams } from 'react-router-dom';
import {
  convertDateFunction,
  formatDate,
} from '../../../../../helpers/functions';
import Dropdown from '../../../../../components/Dropdown';
import TableManager from '../../../../../components/TableManager';
import {
  agentClaimList,
  agentConsumerList,
  getConsumer,
} from '../../../../../api';

const filterInitialValues = {
  filter: [
    {
      key: 'account_number',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter account number',
      inputType: 'number',
      inputLabel: 'Account number',
    },
    {
      key: 'email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter Email',
      inputType: 'email',
      inputLabel: 'Email',
    },
  ],
};

const initialEditInstance = { isEdit: false, editData: {} };
export default function AgentClaim() {
  const { id } = useParams();

  const { SuccessOutlineIcon, CrossIcon } = useIcons();

  const [addMember, setAddMember] = useState(false);

  const { mutate: mutateDashBoardLogin } = useDashBoardLogin();

  const [editUserInstance, setEditUserInstance] = useState(initialEditInstance);
  const { MoreVIcon } = useIcons();
  const navigate = useNavigate();

  const handleDropListClick = async ({ value }, data) => {
    const { _id } = data || {};

    switch (value) {
      case 'edit':
        // setEditUserInstance({ isEdit: true, editData: data });
        // setAddMember(true);
        break;
      case 'change-status':
        // handleChangeStaus(data);
        break;
      case 'view':
        // navigate(`/user-info/${data?.uuid}/profile`);
        break;
      case 'go_to_dashboard':
        mutateDashBoardLogin(_id);

        break;

      default:
        break;
    }
  };

  const handleUserDetailsModal = (data) => {
    // navigate(`/user-info/${data?.uuid}/dashboard`);
    navigate(`/user-info/${data?.uuid}/profile`);
  };

  const statusLookup = {
    NEW: {
      text: 'New',
      class: 'text--success-800 bg--primary-200 p--sm radius--xs',
    },
    ONHOLD: {
      text: 'On Hold',
      class: 'text--danger bg--danger-100 p--sm radius--xs',
    },
    INPROGRESS: {
      text: 'In Progress',
      class: 'text--warning bg--warning-100 p--sm radius--xs',
    },
    COMPLETED: {
      text: 'Completed',
      class: 'text--success bg--success-100 p--sm radius--xs',
    },
    OPEN: { text: 'Open', class: 'text--blue bg--blue-100 p--sm radius--xs' },
  };
  const columns = [
    {
      accessorKey: 'createdAt',
      header: () => '',
      columns: [
        {
          accessorKey: 'createdAt',
          header: () => 'Date',
          cell: ({ getValue }) => convertDateFunction(getValue()),
        },
        {
          accessorKey: 'claimId',
          header: () => 'Claim Id',
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'carrierDetail',
      header: () => <span className="font--sm font--600">Carrier</span>,
      columns: [
        {
          accessorKey: 'carrierDetail',
          header: () => ' Name',
          cell: ({ row }) => {
            let value = row?.original?.carrierDetail;
            return `${value?.firstname} ${value?.lastname}`;
          },
          meta: {
            width: '30%',
            color: '#d32f2f',
          },
        },
        {
          accessorKey: 'carrierDetail.email',
          header: () => ' Email',
          meta: {
            width: '30%',
            color: '#d32f2f',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#d32f2f',
        // width: '20%',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Consumer</span>,
      columns: [
        {
          accessorKey: 'memberDetail',
          header: () => ' Name',
          cell: ({ row }) => {
            let value = row?.original?.memberDetail;
            return `${value?.firstname} ${value?.lastname}`;
          },
          meta: {
            width: '30%',
            color: '#1a9112',
          },
        },
        {
          accessorKey: 'memberDetail.email',
          header: () => ' Email',
          meta: {
            width: '30%',
            color: '#1a9112',
          },
        },
        {
          accessorKey: 'memberDetail.phone',
          header: () => ' Phone',
          meta: {
            color: '#1a9112',
          },
        },
      ],
      meta: {
        colSpan: 3,
        textAlign: 'center',
        color: '#1a9112',
        // width: '20%',

        // border: "none",
      },
    },

    {
      accessorKey: 'signedDate',
      header: () => '',
      columns: [
        {
          accessorKey: 'claimStatus',
          header: () => 'Status',
          cell: ({ row }) => {
            return (
              <span
                className={statusLookup?.[row?.original?.claimStatus]?.class}
              >
                {statusLookup?.[row?.original?.claimStatus]?.text}
              </span>
            );
          },
        },
      ],
      meta: {
        textAlign: 'center',
        colSpan: 2,
      },
    },
  ];

  return (
    <div className="w--full">
      <TableManager
        {...{
          fetcherKey: 'agentClaimList',
          fetcherFn: agentClaimList,
          columns,
          name: 'Claims',
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          selectCBForTableData: (tblData) => tblData?.data?.data?.data,
          extraParams: { uniqueCodeId: id },
          fullTableClass: 'table--responsive--search-scroll--full',
        }}
      />
    </div>
  );
}

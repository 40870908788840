import { Outlet } from 'react-router-dom';
import Alert from '../components/Alert';
import { useAlert } from '../hooks/useAlert';
import useIcons from '../assets/icons/useIcons';

export default function UserInfoLayout() {
  const { alerts } = useAlert();
  const { SuccessOutlineIcon } = useIcons();
  return (
    <main className="d--flex w--full h--full gap--md bg--contrast position--relative">
      <div className="w--full h--full d--flex flex--column gap--sm">
        <div className="h--full w--full  p-r--md">
          <Outlet />
        </div>
      </div>
      <div className="main-alert position--absolute d--flex flex--column gap--sm">
        {alerts &&
          alerts.length > 0 &&
          alerts.map((alert) => (
            <Alert
              key={alert?.id}
              alert={alert}
              icon={<SuccessOutlineIcon width={30} height={30} />}
            />
          ))}
      </div>
    </main>
  );
}

import React from 'react';
import UserGraph from './Components/UserGraph';
import PieChart from './Components/PieChart';
import {
  adminConsentDashboardSates,
  adminConsentPieSates,
  adminHRAConsentCarrierSates,
  adminHRAConsentStateSates,
  adminHRADashboardSates,
  adminUserDashboardSates,
} from '../../api';
import { useQueries } from '@tanstack/react-query';
import ConsentGraph from './Components/ConsentGraph';
import HRAGraph from './Components/HRAGraph';
import Spinner from '../../components/Spinner';
import { Controller, useForm } from 'react-hook-form';
import FormSelect from '../../components/FormSelect';
import { filterTypeArr, yearList } from './constants';

const Dashboard = () => {
  const { watch, control } = useForm({
    defaultValues: {
      filterType: 'yearly',
      customYear: `${new Date().getFullYear()}`,
    },
  });

  const [watchFilterType, watchCustomYear] = watch([
    'filterType',
    'customYear',
  ]);

  const [
    { data: userData, isLoading: isUserDataLoading },
    { data: consentData, isLoading: isConsentDataLoading },
    { data: hraData, isLoading: isHraDataLoading },
    { data: hraConsentCarrierData, isLoading: isHraConsentCarrierDataLoading },
    { data: hraConsentStateData, isLoading: isHraConsentStateDataLoading },
    { data: consentPieData, isLoading: isConsentPieDataLoading },
  ] = useQueries({
    queries: [
      {
        queryKey: ['adminUserDashboardSates', watch()],
        queryFn: () =>
          adminUserDashboardSates({
            filter_type: watchFilterType,
            custom_year: watchCustomYear,
          }),
        select: (data) => data?.data?.data,
      },
      {
        queryKey: ['adminConsentDashboardSates', watch()],
        queryFn: () =>
          adminConsentDashboardSates({
            filter_type: watchFilterType,
            custom_year: watchCustomYear,
          }),
        select: (data) => data?.data?.data,
      },
      {
        queryKey: ['adminHRADashboardSates', watch()],
        queryFn: () =>
          adminHRADashboardSates({
            filter_type: watchFilterType,
            custom_year: watchCustomYear,
          }),
        select: (data) => data?.data?.data,
      },
      {
        queryKey: ['adminHRAConsentCarrierSates', watch()],
        queryFn: () =>
          adminHRAConsentCarrierSates({
            filter_type: watchFilterType,
            custom_year: watchCustomYear,
          }),
        select: (data) => data?.data?.data,
      },
      {
        queryKey: ['adminHRAConsentStateSates', watch()],
        queryFn: () =>
          adminHRAConsentStateSates({
            filter_type: watchFilterType,
            custom_year: watchCustomYear,
          }),
        select: (data) => data?.data?.data,
      },
      {
        queryKey: ['adminConsentPieSates', watch()],
        queryFn: () =>
          adminConsentPieSates({
            filter_type: watchFilterType,
            custom_year: watchCustomYear,
          }),
        select: (data) => data?.data?.data,
      },
    ],
  });

  const isAnyQueryLoading = [
    isUserDataLoading,
    isConsentDataLoading,
    isHraDataLoading,
    isHraConsentCarrierDataLoading,
    isHraConsentStateDataLoading,
    isConsentPieDataLoading,
  ].some((loading) => loading);

  if (isAnyQueryLoading) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <>
      <div className="w--full">
        <div
          className="page_heading page_heading_enhence d--flex justify-content--between
         align-items--center w--full p-1 rounded-0"
        >
          <h3 className="d--flex w--full  mb-0 h-min--36 d--flex align-items--center">
            <span className="font--md font--600">Dashboard</span>
          </h3>
          <div className="w--full d--flex align-items--center justify-content--end w-max--250 ">
            <div className="label--control font--sm font--500  d--flex w-min--40">
              Filter :
            </div>

            <div className="w--full d--flex gap--sm justify-content--end">
              <Controller
                name={`filterType`}
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    options={filterTypeArr}
                    height={32}
                    autocomplete="off"
                    required={false}
                  />
                )}
              />
              {watchFilterType === 'yearly' && (
                <div className="w-max--80 w-min--80">
                  <Controller
                    name={'customYear'}
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        paddingLeft="sm"
                        paddingRight="sm"
                        extraClasses=" "
                        {...field}
                        options={yearList}
                        height={32}
                        autocomplete="off"
                        required={false}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d--flex flex--column dashboardSec overflow--auto">
          <div className={`w--full d--grid grid--3 flex--column gap--md `}>
            <div className="w--full border-full--black-200 radius--md p--md bg--white dash-chart">
              <PieChart graphData={hraConsentStateData?.graph} />
            </div>
            <div className="w--full border-full--black-200 radius--md p--md bg--white dash-chart">
              <PieChart graphData={hraConsentCarrierData?.graph} />
            </div>
            <div className="w--full border-full--black-200 radius--md p--md bg--white dash-chart">
              <PieChart graphData={consentPieData?.graph} />
            </div>
          </div>
          <div
            className={`d--flex ${
              watchFilterType === 'weekly' ? '' : 'flex--column'
            } gap--md m-t--md`}
          >
            <div className="w--full border-full--black-200 radius--md p--md bg--white dash-chart">
              <UserGraph graphData={userData?.graph} />
            </div>
            <div className="w--full border-full--black-200 radius--md p--md bg--white dash-chart">
              <ConsentGraph graphData={consentData?.graph} />
            </div>
            <div className="w--full border-full--black-200 radius--md p--md bg--white dash-chart">
              <HRAGraph graphData={hraData?.graph} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

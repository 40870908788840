import { useMutation } from '@tanstack/react-query';
import { dashboardLogin } from '../../api';
import { useAlert } from '../useAlert';

export default function useDashBoardLogin() {
  const { showAlert } = useAlert();
  const mutateLogin = useMutation({
    mutationFn: (uniqueCode) => dashboardLogin({ uniqueCode }),
    mutationKey: 'dashboardLogin',
    onSuccess: ({ data }) => {
      window.open(
        `${process.env.REACT_APP_PORTAL_URL}verify-access-token/${data?.data?.token?.token}?role=${data?.data?.role}`,
      );
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...mutateLogin };
}

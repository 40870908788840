import { useState } from 'react';
import useIcons from '../../../../../assets/icons/useIcons';
import useDashBoardLogin from '../../../../../hooks/useDashBoardLogin';
import { useNavigate, useParams } from 'react-router-dom';
import {
  convertDateFunction,
  formatDate,
} from '../../../../../helpers/functions';
import Dropdown from '../../../../../components/Dropdown';
import TableManager from '../../../../../components/TableManager';
import { agentConsumerList, getConsumer } from '../../../../../api';

const filterInitialValues = {
  filter: [
    {
      key: 'account_number',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter account number',
      inputType: 'number',
      inputLabel: 'Account number',
    },
    {
      key: 'email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter Email',
      inputType: 'email',
      inputLabel: 'Email',
    },
  ],
};

const initialEditInstance = { isEdit: false, editData: {} };
export default function AgentConsumer() {
  const { id } = useParams();

  const { EyeOffIcon, EyeIcon } = useIcons();

  const { SuccessOutlineIcon, CrossIcon } = useIcons();

  const [addMember, setAddMember] = useState(false);

  const { mutate: mutateDashBoardLogin } = useDashBoardLogin();

  const [editUserInstance, setEditUserInstance] = useState(initialEditInstance);
  const { MoreVIcon } = useIcons();
  const navigate = useNavigate();

  const handleDropListClick = async ({ value }, data) => {
    const { _id } = data || {};

    switch (value) {
      case 'edit':
        // setEditUserInstance({ isEdit: true, editData: data });
        // setAddMember(true);
        break;
      case 'change-status':
        // handleChangeStaus(data);
        break;
      case 'view':
        // navigate(`/user-info/${data?.uuid}/profile`);
        break;
      case 'go_to_dashboard':
        mutateDashBoardLogin(_id);

        break;

      default:
        break;
    }
  };

  const handleUserDetailsModal = (data) => {
    // navigate(`/user-info/${data?.uuid}/dashboard`);
    navigate(`/user-info/${data?.uuid}/profile`);
  };
  const reasonLookUp = {
    CONSENT: 'Consent',
    APP_REVIEW: 'App Review',
    CHANGE_APPROVAL: 'Change Approval',
    MULTI_TYPE: 'Multi Type',
  };
  const columns = [
    {
      accessorKey: 'signedDate',
      header: () => '',
      columns: [
        {
          accessorKey: 'firstName',
          header: () => 'Name',
          cell: (props) =>
            `${props.getValue()} ${props.row.original?.lastName}`,
          meta: {
            width: '30%',
          },
        },
        {
          accessorKey: 'phone',
          header: () => 'Phone',
          meta: {
            textAlign: 'center',
          },
        },
        {
          accessorKey: 'email',
          header: () => 'Email',
          meta: {
            width: '25%',
          },
        },
      ],
      meta: {
        colSpan: 3,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Consent</span>,
      columns: [
        {
          accessorKey: 'submittedDate',
          header: () => 'Submitted Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.submittedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  {!rowData.signedDate && (
                    <span
                      className="d--flex align-items--center justify-content--center c--pointer"
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_PORTAL_URL}view-signature-document/${rowData?.agreementUuid}?docType=CMS`,
                        );
                      }}
                    >
                      <EyeIcon width={15} height={15} />
                    </span>
                  )}
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#1a9112',
          },
        },
        {
          accessorKey: 'signedDate',
          header: () => 'Signed Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.signedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_PORTAL_URL}view-document/${rowData?.agreementUuid}?docType=CMS`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#1a9112',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#1a9112',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Eligibility</span>,
      columns: [
        {
          accessorKey: 'eligibilitySubmittedDate',
          header: () => 'Submitted Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.eligibilitySubmittedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  {!rowData.eligibilitySignedDate && (
                    <span
                      className="d--flex align-items--center justify-content--center c--pointer"
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_PORTAL_URL}view-signature-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                        );
                      }}
                    >
                      <EyeIcon width={15} height={15} />
                    </span>
                  )}
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#d32f2f',
          },
        },
        {
          accessorKey: 'eligibilitySignedDate',
          header: () => 'Signed Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.eligibilitySignedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_PORTAL_URL}view-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#d32f2f',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#d32f2f',
      },
    },
    {
      accessorKey: 'enrollmentYear',
      header: () => '',
      columns: [
        {
          accessorKey: 'enrollmentYear',
          header: () => 'Enrollment',

          meta: {
            textAlign: 'center',
            width: '8%',
          },
        },
        {
          accessorKey: 'reason',
          header: () => 'Reason',
          cell: ({ getValue }) => reasonLookUp[getValue()],
          meta: {
            textAlign: 'center',
            width: '8%',
          },
        },
      ],
      meta: {
        textAlign: 'center',
        colSpan: 2,
      },
    },
  ];
  return (
    <div className="w--full">
      <TableManager
        {...{
          fetcherKey: 'agentConsumerList',
          fetcherFn: agentConsumerList,
          columns,
          name: 'Consumer',
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          selectCBForTableData: (tblData) => tblData?.data?.data?.data,
          extraParams: { uniqueCodeId: id },
          fullTableClass: 'table--responsive--search-scroll--full',
        }}
      />
    </div>
  );
}
